@media (max-width: 1600px) {
    html {
        //font-size: 80%;
    }
}

.mobile-overlay {
    @apply dark:border-dark-border p-4;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    text-align: center;
    z-index: 9999;
    color: #cbd5e1;
}

.nocodex .mat-icon:not(template-grid *) {
    height: 1.5rem;
    width: 1.5rem;
    font-size: 1.5rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1.875rem #000 inset !important;
}

input:-webkit-autofill {
    -webkit-text-fill-color: white !important;
}

body:not(template-grid *) {
    font-family: 'Inter' !important;
    @apply bg-light-background_level_1 text-black;
}

body.dark:not(template-grid *) {
    @apply bg-dark-background_level_1 text-white;
}

template-grid {
    @apply text-black;
}

.mat-typography template-grid h1,
.mat-typography template-grid h2,
.mat-typography template-grid h3,
.mat-typography template-grid h4,
.mat-typography template-grid h5,
.mat-typography template-grid h6 {
    margin: 0 0 0 0;
}

.node-icon {
    height: 2.5rem !important;
    width: 2.5rem !important;
}

.icon {
    height: 1.25rem;
    width: 1.25rem;
}

svg.icon use.fill-ui-element {
    @apply fill-dark-icons-inactive_color;
}

.tabs-tab-active svg.icon use.ui-element {
    stroke: rgb(156 56 255);
}
svg.icon use.ui-element {
    @apply stroke-dark-icons-inactive_color;
}

button.button-medium svg.icon use.ui-element {
    @apply stroke-white;
}

button svg.icon use.ui-element g path {
    @apply stroke-white;
}

svg.icon use.ui-element g path {
    @apply stroke-dark-icons-inactive_color;
}

svg.icon use.ui-element path {
    @apply fill-dark-icons-inactive_color;
}

svg.icon use.material {
    @apply fill-dark-icons-inactive_color;
}

h1:not(template-grid *) {
    @apply grow p-0 dark:text-white;
    @apply text-xl #{!important};
    font-size: 2.25rem !important;
    font-weight: 700 !important;
}

h2:not(template-grid *) {
    @apply grow p-0 dark:text-white;
    @apply text-lg #{!important};
    font-size: 1.875rem !important;
    font-weight: 700 !important;
}

h3:not(template-grid *) {
    @apply grow p-0 dark:text-white;
    @apply text-base #{!important};
    @apply m-0 #{!important};
    font-size: 1.5rem;
}

h4:not(template-grid *) {
    @apply grow p-0 dark:text-white;
    @apply text-sm #{!important};
    @apply m-0 #{!important};
    font-size: 1.25rem;
}

p.large:not(template-grid *) {
    font-size: 1.125rem;
}

p:not(template-grid *) {
    font-size: 1rem;
}

p.small:not(template-grid *) {
    font-size: 0.875rem;
}

p.tiny:not(template-grid *) {
    font-size: 0.75rem;
}

p.micro:not(template-grid *) {
    font-size: 0.688rem;
}

.nocodex .button-extra-small mat-icon {
    height: 1rem;
    width: 1rem;
    font-size: 1rem;
}

.button-extra-small {
    @apply h-6 cursor-pointer;
    @apply p-1 #{!important};
}

.button-small {
    @apply h-7 p-1.5 cursor-pointer;
}

.button-medium {
    @apply h-8 p-2 cursor-pointer;
}

.button-medium svg {
    @apply h-5 w-5 mr-1 text-white;
}

.button-large {
    @apply h-9 p-2.5 cursor-pointer;
}

.button-extra-large {
    @apply h-10 p-3 cursor-pointer;
}

.button-group {
    @apply flex rounded-lg shadow-sm p-0.5 w-full bg-light-background_level_1 dark:bg-dark-background_level_1 h-8 mb-2;
}

.button-group button {
    @apply text-text_2 rounded-lg grow shrink flex justify-center items-center;
}

.button-group .button-group-button-selected {
    @apply bg-light-background_level_0 dark:bg-dark-background_level_0 text-accent;
}

.button-group-button-selected mat-icon {
    @apply text-accent font-light;
}

.button-group-button-selected svg use.ui-element {
    @apply stroke-accent;
}

.primary-button {
    @apply text-sm font-medium text-center inline-flex items-center text-white rounded-lg
  focus:ring-2 focus:ring-offset-2
  bg-light-button-background
  hover:bg-light-button-background_hover
  focus:ring-light-button-background_focus_ring
  dark:bg-dark-button-background
  dark:hover:bg-dark-button-background_hover
  dark:focus:ring-dark-button-background_focus_ring
  dark:focus:ring-offset-dark-button-background_focus_ring_offset;
}

.text-button {
    @apply text-accenttext flex flex-row justify-center items-center gap-2;
}

.text-button svg.icon use.ui-element {
    @apply stroke-accenttext;
}

.primary-button-tooltip {
    @apply absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700;
}

.secondary-button-tooltip {
    @apply absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700;
}

.secondary-button {
    @apply px-3 py-2 text-sm font-medium text-center inline-flex items-center text-white
  bg-light-secondary_button-background dark:bg-light-secondary_button-background
  hover:bg-light-secondary_button-background_hover dark:hover:bg-light-secondary_button-background_hover
  rounded-lg  focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800;
}

.secondary-icon-button {
    @apply text-blue-700 border border-blue-700 hover:bg-light-secondary_button-background_hover dark:hover:bg-dark-secondary_button-background_hover
  hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800;
}

.destructive-icon-button {
    @apply text-blue-700 border border-blue-700 hover:bg-light-destructive_button-background_hover dark:hover:bg-dark-destructive_button-background_hover
  hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800;
}

.simple-icon-button {
    @apply cursor-pointer text-text_2 hover:text-accenttext;
}

.icon-button {
    @apply cursor-pointer;
}

.icon-button use.ui-element {
    @apply stroke-text_2;
}

.icon-button:hover use.ui-element {
    @apply stroke-accent;
}

.icon-button:hover use.fill-ui-element {
    @apply fill-accent;
}

.icon-button use.material {
    @apply fill-text_2;
}

.icon-button:hover use.material {
    @apply fill-accent;
}

.simple-destructive-icon-button {
    @apply cursor-pointer text-light-destructive_button-background dark:text-dark-destructive_button-background hover:text-light-destructive_button-background_hover dark:hover:text-dark-destructive_button-background_hover;
}

.destructive-button-tooltip {
    @apply absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700;
}

.destructive-button {
    @apply px-3 py-2 text-sm font-medium text-center inline-flex items-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800;
}

.buttonContainer {
    @apply flex justify-end gap-1 mt-2;
}

.dropdown-items {
    @apply bg-light-background_level_0 dark:bg-dark-background_level_0
  border border-light-border dark:border-dark-border
  divide-y divide-gray-100
  rounded-lg
  shadow
  w-64;
    z-index: 1000;
}

.dropdown-items-wrapper {
    @apply py-2 text-sm text-gray-700 dark:text-gray-200;
}

.dropdown-item {
    @apply block px-4 py-2 dark:hover:text-white flex justify-start items-center gap-3
  hover:bg-dark-button-background cursor-pointer;
}

// Main

template-edit,
codex-action-edit,
codex-application-overview {
    @apply grow h-full;
}

codex-application-overview {
    height: inherit;
}

codex-data-edit,
codex-data-format-page,
codex-api-edit,
codex-media,
codex-edit-role-page,
codex-edit-group-page,
codex-job-edit,
app-design-system-detail {
    @apply p-4 grow bg-light-background_level_0 dark:bg-dark-background_level_0;
}

codex-data-edit-form {
    @apply h-full block;
}

codex-data-edit-form > form {
    @apply flex flex-col h-full;
}

codex-data-edit-form > form > ngx-monaco-editor,
codex-data-create-form > form > ngx-monaco-editor,
app-html-part-code-editor ngx-monaco-editor {
    @apply grow;
}

codex-data-edit-form .monaco-editor,
codex-data-create-form .monaco-editor,
codex-action-outputs .monaco-editor {
    @apply border dark:border-dark-border border-light-border;
}

codex-data-edit-form .monaco-editor,
codex-data-edit-form .monaco-editor .overflow-guard,
codex-action-outputs .monaco-editor,
codex-action-outputs .monaco-editor .overflow-guard,
codex-data-create-form .monaco-editor,
codex-data-create-form .monaco-editor .overflow-guard {
    @apply rounded-lg;
}

codex-data-edit-form .monaco-editor .minimap-decorations-layer,
codex-data-create-form .monaco-editor .minimap-decorations-layer,
codex-action-outputs .monaco-editor .minimap-decorations-layer {
    @apply w-64 bg-dark-input-background_color;
    @apply p-0 #{!important};
}

codex-data-edit-form .monaco-editor,
codex-data-edit-form .monaco-editor-background,
codex-data-edit-form .monaco-editor .margin,
codex-data-create-form .monaco-editor,
codex-data-create-form .monaco-editor-background,
codex-data-create-form .monaco-editor .margin,
codex-action-outputs .monaco-editor,
codex-action-outputs .monaco-editor-background,
codex-action-outputs .monaco-editor .margin {
    @apply w-64 bg-dark-input-background_color;
    @apply p-0 #{!important};
}

.content-container {
    @apply border dark:border-dark-border border-light-border h-full grow border-t-0 flex flex-col bg-light-background_level_1 dark:bg-dark-background_level_1;
}

codex-console-page {
    @apply grow flex flex-col overflow-x-hidden overflow-y-auto scrollbar scrollbar-primary;
}

.action-editor #editor-tab-content {
    overflow: hidden;
}

#editor-tab-content {
    @apply grow overflow-y-auto overflow-x-hidden;
}

.editor-tab-panel {
    @apply rounded-lg bg-transparent h-full;
}

.editor-tab-wrapper:has(> codex-action-edit),
.editor-tab-wrapper:has(> template-edit) {
    @apply h-full;
}

.editor-tab-wrapper {
    @apply rounded-lg bg-transparent flex min-h-full;
}

.content-header {
    @apply border-b dark:border-b-dark-border border-b-light-border h-[3rem] flex justify-between bg-light-background_level_0 dark:bg-dark-background_level_0 rounded-r-lg pr-1 pl-1 flex-none;
}

.content-right-navigation,
.content-navigation {
    @apply flex gap-1 items-center;
}

.content-workspace-navigation {
    @apply flex gap-1 border-r border-light-border dark:border-dark-border pr-1;
}

.workspace-selector-icon-wrapper {
    @apply flex w-8 h-8 flex-col justify-center items-center text-accent bg-accentlight rounded-lg;
}

codex-company-selector {
    @apply border-r border-light-border dark:border-dark-border pr-1;
}

.content-right-navigation .link:hover,
.generative-container .link:hover,
.application-overview-node .link:hover {
    @apply border dark:border-dark-border border-light-border bg-accentlight;
}

.content-right-navigation .link:hover svg use,
.generative-container .link:hover svg use,
.application-overview-node .link:hover svg use {
    @apply stroke-accent;
}

.content-right-navigation .link,
.generative-container .link,
.application-overview-node .link {
    @apply p-1 rounded-lg w-8 h-8 cursor-pointer border border-transparent;
}

.action-container .link {
    @apply border dark:border-dark-border border-light-border dark:bg-dark-background_level_1;
}

.content-right-navigation .link mat-icon {
    @apply text-[1.25rem] h-6 w-6 text-center;
    line-height: 1.5rem;
}

.avatar-content {
    font-family: 'Inter' !important;
}

.sidenav {
    @apply w-[3rem] flex flex-col items-center h-full bg-light-background_level_1 dark:bg-dark-background_level_1;
}

.sidenav-items {
    @apply w-[1.875rem] flex flex-col items-center h-full gap-2 pt-2;
}

.sidenav-item-wrapper {
    @apply w-full h-full flex justify-between flex-col items-center bg-light-background_level_0 dark:bg-dark-background_level_0 rounded-l-lg;
}

.sidenav-items .link {
    @apply h-[1.875rem] w-[1.875rem] flex-grow-0 flex-shrink-0 dark:text-dark-sidenav-primary text-light-sidenav-primary rounded hover:bg-accentlight;
}

.sidenav-items .link:hover svg.icon use.ui-element {
    @apply stroke-accent;
}

.sidenav-items .link:hover svg.icon use.material,
.sidenav-items .link:hover svg.icon use.fill-ui-element {
    @apply fill-accent;
}

.sidenav .link,
.content-navigation .link {
    @apply dark:text-dark-sidenav-primary text-light-sidenav-primary flex items-center justify-center cursor-pointer;
}

authenticated {
    @apply flex h-screen;
    //background:
    //    radial-gradient(50% 50% at 50% 50%, rgba(66, 0, 255, 0.2) 0%, rgba(0, 178, 255, 0) 100%) top 146% left 103% / 160% 189% no-repeat,
    //    radial-gradient(50% 50% at 50% 50%, rgba(97, 0, 255, 0.5) 0%, rgba(255, 152, 107, 0) 100%) top -69% left -72% / 139% 151% no-repeat;
}

.logo {
    @apply w-6;
}

.logo-wrapper {
    @apply w-12 h-12 grow-0 shrink-0 flex justify-center border-b dark:border-dark-border border-light-border bg-dark-background_level_0 rounded-l-lg;
}

// Forms
textarea.small-input,
textarea.alt-small-input {
    height: 8em;
}

.input-button-container {
    position: relative;
    display: inline-block;
    @apply w-full;
}

.input-button-container .small-input {
    padding-right: 2rem; /* Adjust for the width of the SVG */
    width: 100%; /* Adjust as needed */
    box-sizing: border-box; /* Include padding in width calculation */
}

.input-button-container .input-button {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
}

.input-button-container .input-button:hover .ui-element {
    @apply stroke-light-button-background dark:stroke-dark-button-background;
}

.alt-small-input {
    @apply block w-full p-2
  border border-[#1E293B]
  text-white dark:text-white
  rounded-lg
  bg-[#020617] dark:bg-[#020617]
  text-xs focus:ring-light-button-background
  focus:border-light-button-border_color dark:placeholder-gray-400 dark:focus:ring-light-button-background
  dark:focus:border-light-button-border_color mb-2 h-[2.125rem];
}

codex-part-style-wrapper .small-input {
    @apply max-w-[6.25rem];
}

codex-part-style-wrapper select.small-input {
    @apply max-w-[11.25rem];
}

.small-input {
    @apply block w-full p-2
  border
  text-white dark:text-white
  rounded-lg
  bg-dark-input-background_color
  border-dark-input-border_color
  text-xs focus:ring-light-button-background
  focus:border-light-button-border_color dark:placeholder-gray-400 dark:focus:ring-light-button-background
  dark:focus:border-light-button-border_color mb-2 h-[2.125rem];
}

.small-dropdown-button {
    @apply flex w-full p-2
  items-center
  border
  text-white dark:text-white
  rounded-lg
  bg-dark-input-background_color
  border-dark-input-border_color
  focus:ring-light-button-background
  focus:border-light-button-border_color dark:focus:ring-light-button-background
  dark:focus:border-light-button-border_color h-[2.125rem];
}

.small-dropdown-items {
    @apply absolute right-0 rounded-lg
  bg-dark-input-background_color
  border-dark-input-border_color
  w-full border
  text-xs text-white;
    z-index: 1000;
}

.small-dropdown-item {
    @apply block px-4 py-2 text-xs text-white hover:bg-accent cursor-pointer rounded-lg;
}

.small-number-input {
    @apply max-w-[4.375rem];
}

.small-viewport-selector {
    @apply mb-2;
}

.small-color-picker {
    @apply mb-2 max-w-[11.25rem];
}

.small-number-wrapper,
.small-text-wrapper,
.small-dropdown-wrapper,
.small-color-picker-wrapper {
    @apply flex flex-row gap-2 justify-between items-center;
}

label.small-input-label-with-override-options {
    justify-content: center;
    align-items: center;
    justify-items: center;
    border-radius: 0.25rem;
    cursor: pointer;
    padding: 0.125rem;
    @apply text-dark-input-label_color dark:text-dark-input-label_color;
}

label.responsive:hover {
    @apply dark:bg-dark-input-label_background_color_hover;
}

label.responsive {
    color: #dfad00 !important;
}

.small-input-left-part::-webkit-search-decoration,
.small-input-left-part::-webkit-search-cancel-button,
.small-input-left-part::-webkit-search-results-button,
.small-input-left-part::-webkit-search-results-decoration {
    display: none;
}

.small-input-left-part::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

.small-input-left-part::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

.small-input-left-part {
    @apply block w-full
  p-2
  border-0
  text-white dark:text-white
  rounded-none rounded-s-lg
  bg-light-background_level_1 dark:bg-dark-background_level_1
  text-xs focus:ring-light-button-background
  focus:border-light-button-border_color dark:placeholder-gray-400 dark:focus:ring-light-button-background
  dark:focus:border-light-button-border_color
  leading-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none;
}

select.small-input-right-part:not([size]) {
    background-position: right 0.5rem center;
    padding-right: 1.25rem;
}

select option:hover,
select option:focus,
select option:active {
    @apply bg-accentlight text-white;
}

select option:checked {
    @apply bg-accentlight text-white;
}

.small-input-right-part {
    @apply block w-full
  p-2 border-0
  text-light-input-background_color dark:text-dark-input-placeholder_color
  rounded-none rounded-e-lg
  bg-light-background_level_1 dark:bg-dark-background_level_1
  text-xs focus:ring-light-button-background
  focus:border-light-button-border_color dark:placeholder-gray-400 dark:focus:ring-light-button-background
  dark:focus:border-light-button-border_color
  leading-none flex-1;
    @apply pr-1;
}

.small-input-label {
    @apply block mb-2 text-sm font-medium text-dark-input-label_color dark:text-dark-input-label_color relative w-fit pr-4 flex gap-1;
}

.small-input-error {
    @apply mt-2 text-sm text-red-600 dark:text-red-500;
}

.small-input-hint {
    @apply mt-2 text-sm text-gray-500 dark:text-text_2;
}

.checkbox {
    @apply w-4 h-4
  text-light-button-background dark:text-dark-button-background
  bg-light-input-background_color dark:bg-dark-input-background_color
  border border-light-input-border_color dark:border-dark-input-border_color
  rounded
  focus:ring-light-button-background
  dark:focus:ring-light-button-background
  dark:ring-offset-gray-800
  focus:ring-2;
}

.toggle-line {
    @apply flex text-sm items-center mb-2 cursor-pointer justify-between text-dark-input-label_color dark:text-dark-input-label_color font-medium;
}

.toggle-line .toggle {
    @apply relative w-[1.5rem] h-[0.875rem] rounded-full
  bg-light-input-background_color dark:bg-dark-input-background_color
  after:content-[''] after:absolute after:top-[0.125rem] after:start-[0.125rem]
  after:bg-white after:border-light-input-border_color after:border after:rounded-full after:h-[0.625rem] after:w-[0.625rem] after:transition-all
  border-light-input-border_color dark:border-dark-input-border_color;
}

.input-group {
    @apply flex items-center mb-2 gap-1;
}

.input-group .input {
    @apply grow;
}

.input-group .small-input {
    @apply mb-0;
}

// Left/Right drawer
// Used for:
// - Component picker & Navigator on Template editor.
// - Function picker on Action editor.

template-edit,
codex-action-edit {
    @apply relative;
}

.left-drawer {
    @apply absolute h-full top-0 flex flex-col z-40 pb-2 transition-transform -translate-x-full bg-light-background_level_0
  w-[21.75rem]
  left-[21.75rem]
  dark:bg-dark-background_level_0 border-r dark:border-dark-border border-light-border rounded-r-lg;
    @apply p-0 #{!important};
    --tw-bg-opacity: 0.9 !important;
    backdrop-filter: blur(0.25rem);
}

.right-drawer {
    @apply absolute top-0 h-full flex flex-col right-0 z-40 pb-2 transition-transform bg-light-background_level_0 w-80 dark:bg-dark-background_level_0 border-l dark:border-dark-border border-light-border
  rounded-l-lg;
    @apply p-0 #{!important};
    --tw-bg-opacity: 0.9 !important;
    backdrop-filter: blur(0.25rem);
}

.right-drawer-block.right-drawer-block-full {
    @apply h-full;
}

.left-drawer-block,
.right-drawer-block {
    @apply overflow-y-auto h-[50%] scrollbar scrollbar-primary overflow-x-hidden;
    @apply mb-0 #{!important};
}

.left-drawer-seperator,
.right-drawer-seperator {
    @apply h-px my-0.5 bg-light-border border-0 dark:bg-dark-border;
}

.drawer-title {
    @apply flex items-center pl-2 pr-2 h-12 border-b border-light-border dark:border-dark-border;
}

.drawer-search-section {
    @apply flex items-center pl-2 pr-2 h-12 pl-2 pr-2 pt-2;
}

// Accordion styling
// Used for:
// - Component picker on template-editor
// - Properties in dataformat editor.

.accordion-heading {
    @apply flex items-center justify-between w-full h-[2.5rem] px-2 py-1 text-sm
  rtl:text-right
  border-t border-l border-r border-light-border dark:border-dark-border
  bg-light-accordion-background dark:bg-dark-accordion-background
  text-light-accordion-header_text_color dark:text-dark-accordion-header_text_color
  hover:bg-light-accordion-background_hover dark:hover:bg-dark-accordion-background_hover
  hover:text-white gap-1;
}

.alt-accordion-heading {
    @apply flex items-center justify-between w-full h-[2.5rem] px-2 py-1 text-sm
  rtl:text-right
  border-t border-[#334155] dark:border-[#334155]
  bg-light-accordion-background dark:bg-dark-accordion-background
  text-light-accordion-header_text_color dark:text-dark-accordion-header_text_color
  hover:bg-light-accordion-background_hover dark:hover:bg-dark-accordion-background_hover
  hover:text-white gap-1;
}

.accordion-heading-first:not(.accordion-heading-last) {
    @apply flex items-center
  justify-between w-full px-2 py-1
  font-medium rtl:text-right border-light-border dark:border-dark-border gap-1;
    @apply border-t #{!important};
}

.accordion-heading-first.border-t-0 {
    border-top: 0rem;
}

.accordion-heading-icon {
    @apply flex items-center break-all h-full w-full;
}

.accordion-heading-button {
    @apply flex justify-center cursor-pointer hover:text-white items-center;
}

.destructive-accordion-heading-button {
    @apply text-light-destructive_button-background dark:text-dark-destructive_button-background;
}

.accordion-heading-last {
    @apply flex items-center justify-between w-full px-2 py-1 font-medium rtl:text-right
  border border-light-border dark:border-dark-border gap-1;
}

.accordion-panel {
    @apply p-2 border-l border-r border-b border-light-border dark:border-dark-border;
}

.accordion-panel h5 {
    @apply font-bold #{!important};
    @apply mb-2 #{!important};
    @apply text-white;
    font-size: 1rem !important;
}

.accordion-borderless .accordion-panel:not(.accordion-border > *),
.accordion-borderless .accordion-heading:not(.accordion-border > h3 > *) {
    @apply border-l-0 border-r-0;
}

.accordion-borderless .accordion-heading:not(.accordion-border > h3 > *) {
    @apply border-b border-l-0 border-r-0 border-t-0;
}

.accordion-panel-last {
    @apply border-b border-t-0;
}

// Template grid
// Used for
// - Template editor

.gridContainer.currentTool-selection {
    @apply cursor-default;
}

.gridContainer.currentTool-panning {
    @apply cursor-grab;
}

.gridContainer {
    @apply w-full h-full overflow-hidden;
    background-color: #09090b;
}

.template-specific-actions,
.action-specific-actions {
    @apply fixed top-3;
    left: 50%;
    margin-left: -7.813rem;
    z-index: 999;
}

.template-specific-actions .screensize-selector svg,
.template-specific-actions .toolbox-controls svg {
    @apply cursor-pointer;
}

.template-specific-actions .screensize-selector,
.template-specific-actions .toolbox-controls {
    @apply flex gap-4;
}

.template-specific-actions .extra-menu,
.action-specific-actions .extra-menu {
    @apply flex gap-2;
}

.template-specific-actions .screensize-selector svg:hover use.ui-element {
    @apply stroke-light-button-background dark:stroke-dark-button-background;
}

.template-specific-actions .screensize-selector svg:hover use.material {
    @apply fill-light-button-background dark:fill-dark-button-background;
}

.template-specific-actions .selected-screen-size use.ui-element,
.template-specific-actions .current-tool use.ui-element {
    @apply stroke-light-button-background dark:stroke-dark-button-background;
}

.template-specific-actions .selected-screen-size use.material,
.template-specific-actions .current-tool use.material {
    @apply fill-light-button-background dark:fill-dark-button-background;
}

.ghost-drag {
    position: absolute;
    pointer-events: none;
    background-color: #ececf5;
    border: 0.063rem solid #9c38ff;
    border-radius: 0.188rem;
    opacity: 65%;
    z-index: 999999;
}

// Action grid
// Used for
// - Action editor

.invocation-settings,
.part-settings,
.edge-settings,
.invocation-log-lines {
    @apply rounded-lg bg-[#0F172A] border border-[#334155] relative;
    z-index: 1;
}

.invocation-settings-title,
.edge-settings-title,
.part-settings-title,
.invocation-log-lines-title {
    @apply flex gap-2.5 p-4 border-b border-[#334155] items-center;
}

.invocation-settings-title .invocation-icon {
    @apply bg-[#1E293B] p-1 rounded-lg text-accent h-9 w-9;
    font-size: 1.813rem;
}

.part-settings-title .part-icon,
.invocation-log-lines-title .invocation-log-lines-icon {
    @apply bg-[#1E293B] p-2 rounded-lg  h-9 w-9;
}

.part-settings-title .part-icon use,
.invocation-log-lines-title .invocation-log-lines-icon use {
    @apply stroke-accent #{!important};
}

#part-settings,
#invocation-settings,
#edge-settings,
#invocation-log-lines {
    z-index: 999;
}

#invocation-settings #arrow,
#edge-settings #edge-arrow,
#part-settings #arrow,
#invocation-log-lines #invocation-log-lines-arrow {
    @apply absolute bg-[#334155];
    height: 1.563rem;
    width: 1.563rem;
    z-index: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.edge-settings-body {
    @apply flex flex-col scrollbar scrollbar-primary p-4;
    overflow: auto;
}

#invocation-configuration-arguments,
#action-configuration-parameters,
#action-configuration-output {
    height: calc(100vh - 227px);
    overflow: auto;
    @apply scrollbar scrollbar-primary;
}

#accordion-action-selection-edge-body {
    max-height: 18.75rem;
    overflow: auto;
    @apply scrollbar scrollbar-primary;
}

.actionGrid canvas {
    @apply text-gray-900 dark:text-white;
    background-color: #020617;
}

.actionGrid .edgeLabel {
    @apply bg-light-background_level_0 dark:bg-dark-background_level_0
  border border-dark-card-background_color dark:border-dark-card-background_color
  dark:text-white rounded-lg flex justify-start items-center p-2 gap-2;
    font-family: Inter;
}

.actionGrid .invocation .invocationIcon {
    @apply h-10 w-10 p-2 flex
  justify-center items-center rounded-lg;
    background-color: #1e293b;
}

.actionGrid .invocation .invocationIcon i {
    @apply text-accent;
}

.actionGrid .invocation {
    @apply border
  hover:bg-accentlight dark:hover:bg-accentlight text-gray-900 dark:text-white rounded-lg h-full
  flex justify-between items-center p-4 gap-2;
    border: 0.063rem solid rgba(128, 0, 255, 0.75);
    background-color: #0f172a;
    height: calc(100% - 0.938rem);
    margin: 0.438rem;
}

.actionGrid .invocation-settings {
    @apply w-[12.5rem] h-[12.5rem] bg-accent absolute;
    right: -12.5rem; // De helft van de size van deze block
    top: -3.75rem; // De helft van de height van deze block + de helft van de size van een invocation block (80px / 2)
}

.actionGrid .invocation.selected {
    background-color: rgba(128, 0, 255, 0.1);
    background-image: none;
    animation: glow 1200ms ease-out infinite alternate;
}

.actionGrid .invocation .invocation-log-lines-button {
    position: absolute;
    right: -0.313rem;
    top: -0.313rem;
    border-radius: 1.875rem;
    display: none;
    padding: 0.313rem;
}

.actionGrid .invocation .invocation-log-lines-button svg.icon use.fill-ui-element {
    @apply fill-white;
}

.actionGrid .invocation.log-line .invocation-log-lines-button {
    display: block;
}

.actionGrid .invocation.log-ERROR .invocation-log-lines-button {
    background-color: #ff003d;
}

.actionGrid .invocation.log-ERROR {
    border: 0.063rem solid #ff003d;
}

.actionGrid .invocation.log-WARN .invocation-log-lines-button {
    background-color: #ff7a00;
}

.actionGrid .invocation.log-WARN {
    border: 0.063rem solid #ff7a00;
}

.actionGrid .invocation.log-DEBUG .invocation-log-lines-button {
    background-color: #bbbbbb;
}

.actionGrid .invocation.log-DEBUG {
    border: 0.063rem solid #bbbbbb;
}

.actionGrid .invocation.log-INFO .invocation-log-lines-button {
    background-color: #2b74cd;
}

.actionGrid .invocation.log-INFO {
    border: 0.063rem solid #2b74cd;
}

.actionGrid .invocation.log-TRACE {
    border: 0.063rem solid #bbbbbb;
}

.actionGrid .invocation.log-TRACE .invocation-log-lines-button {
    background-color: #bbbbbb;
}

.actionGrid .invocation.new-invocation,
template-grid part {
    animation: bounce-in 400ms ease-out;
}

.actionGrid .invocation.remove-invocation,
template-grid part.remove-part {
    animation: fade-out-right 400ms ease-out;
    opacity: 0%;
}

@keyframes fade-out-right {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(1.25rem);
    }
}

.f-connection-path {
    stroke-width: 2px;
    stroke-dasharray: 5, 5;
    animation: dash_flow 5s linear infinite;
}

@keyframes dash_flow {
    0% {
        stroke-dashoffset: 100;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes glow-bottom {
    0% {
        border-color: rgba(128, 0, 255, 0.4);
    }
    100% {
        border-color: rgba(128, 0, 255, 1);
    }
}

@keyframes glow {
    0% {
        border-color: rgba(128, 0, 255, 0.4);
        box-shadow:
            0 0 0.313rem rgba(128, 0, 255, 0.2),
            inset 0 0 0.313rem rgba(128, 0, 255, 0.1),
            0 0.125rem 0 #000;
    }
    100% {
        border-color: rgba(128, 0, 255, 1);
        box-shadow:
            0 0 1.25rem rgba(128, 0, 255, 0.6),
            inset 0 0 0.625rem rgba(128, 0, 255, 0.4),
            0 0.125rem 0 #000;
    }
}

@keyframes bounce-in {
    0% {
        opacity: 0;
        transform: scale(0.3);
    }
    50% {
        opacity: 1;
        transform: scale(1.05);
    }
    70% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
}

.actionGrid .invocation .invocation-actions {
    @apply flex gap-1;
}

.actionGrid .invocation .invocation-actions li {
    @apply h-8 w-8 rounded-lg grid place-items-center border-none cursor-pointer;
    background-color: #1e293b;
}

.actionGrid .invocation .invocation-actions li:hover {
    background-color: #1e1e1e;
}

.actionGrid .invocation .invocationLabel {
    @apply h-fit w-fit flex items-center justify-start grow shrink gap-2;
}

.actionGrid .invocation .invocationName {
    @apply text-xs pl-1 font-bold dark:text-white text-gray-900 h-fit grow shrink overflow-hidden;
    font-family: 'Inter';
}

.actionGrid .invocation .invocationName div {
    @apply text-wrap;
}

.actionGrid .invocation .invocationName .functionName {
    @apply text-xxs text-text_2 dark:text-text_2;
}

// Draggable grid
// used for:
// - Component picker on template-editor
// - Function picker on action-editor

.draggables-grid {
    @apply grid grid-cols-3;
}

.draggable-card {
    @apply overflow-hidden font-medium px-1 py-1 text-center flex block bg-transparent hover:bg-accentlight dark:hover:bg-accentlight text-light-draggable-text_color dark:text-text_2
  h-[5.188rem] cursor-pointer flex items-center flex-col justify-start pt-4;
}

.draggable-card mat-icon {
    @apply h-8 w-8 text-accenttext;
    font-size: 2rem;
}

.draggable-card-title {
    @apply text-xxs block leading-3 pt-1;
}

float-ui-content {
    z-index: 99999;
}

.float-ui-container {
    border: none !important;
    padding: 0rem !important;
    box-shadow: none !important;
}

.popover {
    @apply bg-light-background_level_1 dark:bg-dark-background_level_1 border border-light-border dark:border-dark-border;
}

.tooltip {
    @apply border-light-border;
    @apply bg-light-background_level_0 #{!important};
    @apply border #{!important};
    @apply rounded-lg #{!important};
    @apply p-2 #{!important};
}

.draggable-card-popover {
    @apply absolute z-10 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 border
  border-light-border dark:border-dark-border rounded-lg opacity-0 dark:text-text_2 dark:bg-gray-800;
}

.draggable-card-popover-title-without-text {
    @apply text-white;
}

.draggable-card-popover-title {
    @apply text-white mb-2;
}

.draggable-card-popover-description {
    @apply text-light-draggable-text_color dark:text-text_2;
}

.draggable-card-popover-title-element {
    @apply font-semibold dark:text-white;
    font-size: 14px;
}

.draggable-card-help {
    @apply absolute top-1 right-1;
}

// Search dialogs in editor

.template-search,
.api-search,
.dataformat-search,
.data-search,
.job-search,
.media-search,
.action-search,
.toolpane mat-expansion-panel {
    @apply bg-light-background_level_0 dark:bg-dark-background_level_0 overflow-hidden;
}

.template-search,
.api-search,
.dataformat-search,
.data-search,
.job-search,
.media-search,
.action-search {
    max-height: 95vh !important;
    @apply scrollbar scrollbar-primary;
}

.search-results-wrapper {
    @apply flex flex-row gap-1;
}

mat-paginator:not(template-grid *) {
    @apply text-gray-900 dark:text-white;
    @apply bg-transparent #{!important};
}

mat-paginator:not(template-grid *) .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
    @apply fill-gray-500 dark:fill-gray-400;
}

mat-paginator:not(template-grid *) .mat-mdc-paginator-icon {
    @apply fill-gray-900 dark:fill-white;
}

.overview-items {
    @apply pt-4 pb-4 pl-[0.063rem] pr-1 flex-auto w-[70%] gap-1 flex flex-col scrollbar scrollbar-primary overflow-y-auto;
}

.overview-items-full {
    @apply pt-4 pb-4 pl-[0.063rem] pr-1 flex-auto w-full gap-1 flex flex-col;
}

.overview-item:not(.overview-item-progress-wrapper *) {
    @apply border #{!important};
    @apply border-light-border #{!important};
    @apply dark:border-dark-border #{!important};
    @apply rounded-lg #{!important};
    @apply h-[3.125rem]
  bg-light-background_level_0 dark:bg-dark-background_level_0
  shadow
  hover:bg-accentlight dark:hover:bg-accentlight
  text-gray-900 dark:text-white
  cursor-pointer;
}

.overview-item-progress-wrapper {
    @apply border #{!important};
    @apply border-light-border #{!important};
    @apply dark:border-dark-border #{!important};
    @apply rounded-lg #{!important};
    @apply h-[3.125rem]
  bg-light-background_level_0 dark:bg-dark-background_level_0
  hover:bg-accentlight dark:hover:bg-accentlight
  text-gray-900 dark:text-white
  cursor-pointer overflow-hidden flex flex-col;
}

.overview-item {
    @apply flex block pl-2 pr-2 grow;
}

.overview-hub-item {
    @apply flex block
  bg-light-background_level_0 dark:bg-dark-background_level_0 shadow
  hover:bg-accentlight dark:hover:bg-accentlight
  text-gray-900 dark:text-white
  border border-light-border dark:border-dark-border rounded-lg
  h-[6.25rem] cursor-pointer p-2 flex-col;
}

.overview-hub-item-main-info {
    @apply flex gap-1;
}

.overview-item .icon {
    @apply h-12 flex justify-center items-center mr-1 gap-1;
    width: unset;
}

.overview-item .thumbnail {
    @apply flex justify-center items-center mr-1 gap-1 self-center h-[2.5rem] w-[2.5rem] rounded-lg overflow-hidden object-cover;
}

.overview-hub-item .thumbnail {
    @apply flex justify-center items-center mr-1 gap-1 self-center h-[5rem] w-[5rem] rounded-lg overflow-hidden object-cover object-center;
}

.overview-item .audit-data {
    @apply text-gray-500 dark:text-text_2 flex-[42%] flex-col flex justify-center text-xxs;
}

.overview-hub-item .audit-data {
    @apply text-gray-500 dark:text-text_2 text-xxs flex-row flex justify-start gap-1;
}

.overview-item .attribute-icons {
    @apply flex justify-end items-center;
}

.overview-item .actions,
.overview-hub-item .actions {
    @apply flex justify-center items-center;
}

.overview-item .product-reference,
.overview-hub-item .product-reference {
    @apply text-gray-500 dark:text-text_2 text-xxs flex flex-row gap-1;
}

.overview-item .name-data,
.overview-hub-item .name-data {
    @apply flex-[50%] flex-col flex justify-center;
}

.overview-item .name-data .name,
.overview-hub-item .name-data .name {
    @apply text-sm font-bold;
}

.overview-filter-card {
    @apply block p-2 bg-light-background_level_0 dark:bg-dark-background_level_0 border border-light-border dark:border-dark-border rounded-lg shadow;
}

/** Scrollbar **/

.scrollbar {
    @apply overflow-y-auto;
}

.force-overflow {
    min-height: 28.125rem;
}

.scrollbar-primary::-webkit-scrollbar,
.mat-drawer-inner-container::-webkit-scrollbar {
    @apply w-2 h-2 rounded-full;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}

.scrollbar-primary::-webkit-scrollbar-thumb,
.mat-drawer-inner-container::-webkit-scrollbar-thumb {
    @apply rounded-full bg-accent z-50;
}

.scrollbar::-webkit-scrollbar-thumb {
    @apply bg-accent;
    visibility: hidden;
}

.scrollbar:hover::-webkit-scrollbar-thumb {
    visibility: visible;
}

// Inner accordion (deprecated)

/** Inner accordion **/
.inner-accordion {
    margin-bottom: 0.625rem;
    display: block;
}

.inner-accordion .mat-expansion-panel-body {
    @apply pl-1 #{!important};
    @apply pr-1 #{!important};
    @apply bg-white dark:bg-gray-900 text-gray-900 dark:text-white;
}

.inner-accordion mat-expansion-panel {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-radius: 0 !important;
}

.inner-accordion mat-expansion-panel:last-of-type,
.inner-accordion .mat-expansion-panel:last-of-type {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.inner-accordion mat-expansion-panel-header:hover mat-panel-title,
.inner-accordion mat-expansion-panel-header:hover mat-panel-description,
.inner-accordion mat-expansion-panel-header:hover .mat-expansion-indicator::after,
.inner-accordion mat-expansion-panel-header:focus mat-panel-title,
.inner-accordion mat-expansion-panel-header:focus mat-panel-description,
.inner-accordion mat-expansion-panel-header:focus .mat-expansion-indicator::after {
    color: #9c38ff;
}

.inner-accordion mat-expansion-panel-header:hover,
.inner-accordion mat-expansion-panel-header:focus {
    background: white;
}

.inner-accordion mat-expansion-panel-header {
    background: #9c38ff;
    border-bottom: 0.063rem solid #35357d;
    transition: 0.2s;
}

.inner-accordion .mat-expansion-panel-header.mat-expanded,
.inner-accordion .mat-expansion-panel-header {
    height: 2.5rem;
}

.inner-accordion mat-panel-title {
    min-height: 1.25rem;
}

.inner-accordion mat-panel-title,
.inner-accordion mat-panel-description,
.inner-accordion .mat-expansion-indicator::after {
    color: white;
    font-size: 0.75rem;
    font-weight: 400;
}

.inner-accordion mat-panel-title {
    align-items: unset;
    justify-content: space-between;
    margin-right: 0.313rem;
}

.inner-accordion mat-panel-title span.subtitle {
    display: flex;
    gap: 0.313rem;
}

.inner-accordion mat-panel-title > mat-icon {
    margin-right: 0.625rem;
}

.inner-accordion .mat-expansion-panel-body {
    padding: 0.625rem;
}

.inner-accordion .deleteButton {
    width: 1.563rem;
    height: 1.563rem;
}

.inner-accordion .deleteButton .mat-button-wrapper {
    top: -0.5rem;
    position: absolute;
    right: 0rem;
}

// Navigator
codex-template-navigator {
    display: block;
    height: 100%;
}

codex-template-navigator mat-tree {
    @apply bg-transparent #{!important};
}

codex-template-navigator mat-nested-tree-node,
codex-template-navigator mat-tree-node {
    @apply text-gray-900 dark:text-white;
}

codex-template-navigator .navigator-node {
    @apply border-b border-light-border dark:border-dark-border;
}

// Editor tabs

.editor-tabs > .mat-mdc-tab-body-wrapper > mat-tab-body {
    @apply bg-light-background_level_0 dark:bg-dark-background_level_0;
}

// Dialogs
.mat-mdc-dialog-container .mdc-dialog__surface {
    background-color: transparent !important;
}

mat-dialog-content,
.driver-popover {
    @apply bg-light-background_level_0 dark:bg-dark-background_level_0 overflow-hidden border dark:border-dark-border border-light-border rounded-xl;
    @apply p-0 #{!important};
    @apply overflow-x-hidden #{!important};
    --tw-bg-opacity: 0.9 !important;
    backdrop-filter: blur(0.25rem);
}

.driver-popover {
    @apply p-4 #{!important};
    @apply max-w-[60vw] #{!important};
}

.driver-intro {
    @apply text-center;
}

.driver-popover-title {
    @apply grow p-0 dark:text-white mb-4;
    @apply text-xl #{!important};
    font-size: 2.25rem !important;
    font-weight: 700 !important;
}

.driver-popover-description,
.driver-popover-progress-text {
    @apply text-sm text-text_2 pl-2 pb-2;
}

.driver-popover-navigation-btns .driver-popover-btn-disabled {
    visibility: hidden;
}

.driver-popover-navigation-btns button {
    all: unset;
    @apply text-sm font-medium text-center inline-flex items-center text-white rounded-lg
  focus:ring-2 focus:ring-offset-2
  bg-light-button-background
  hover:bg-light-button-background_hover
  focus:ring-light-button-background_focus_ring
  dark:bg-dark-button-background
  dark:hover:bg-dark-button-background_hover
  dark:focus:ring-dark-button-background_focus_ring
  dark:focus:ring-offset-dark-button-background_focus_ring_offset
  h-7 p-1.5 cursor-pointer;
}

// Seperator

hr.seperator {
    @apply h-px my-2 bg-light-border dark:bg-dark-border w-full border-0;
}

hr.vertical-seperator {
    @apply w-px mx-0.5 bg-light-border dark:bg-dark-border h-full border-0;
}

// Icon grid
// Used in:
// - icon picker
.icon-grid {
    @apply grid grid-cols-5 gap-1 mb-2 pl-1 pr-1;
}

.icon-card {
    @apply overflow-hidden font-medium rounded-lg px-1 py-1 text-center flex block bg-light-card-background_color dark:bg-dark-card-background_color border border-light-border dark:border-dark-border rounded-lg shadow hover:bg-light-card-background_hover_color dark:hover:bg-dark-card-background_hover_color text-gray-900 dark:text-white
  h-[2.5rem] cursor-pointer flex justify-center items-center flex-col;
}

// Choice grid
// Used in:
// - create template
.choice-grid,
.choice-grid-center {
    @apply grid grid-cols-5 gap-1 mb-2 pl-1 pr-1;
}

.choice-grid-10 {
    @apply flex gap-1 mb-2 pl-1 pr-1;
}

.choice-grid-center {
    justify-items: center;
    @apply grid-cols-3;
}

.disabled-choice-card {
    opacity: 0.5;
}

.choice-card,
.choice-card-big {
    @apply overflow-hidden font-medium px-1 py-1 text-center flex block
  bg-light-input-background_color dark:bg-dark-input-background_color border border-light-border dark:border-dark-border
  rounded-lg shadow hover:bg-accenttext dark:hover:bg-accenttext text-gray-900 dark:text-white
  h-[6.25rem] cursor-pointer flex justify-center items-center flex-col;
    max-width: 5.625rem;
}

.choice-card {
    min-width: 100px;
}

.choice-card-big {
    max-width: 15.625rem;
    height: 9.375rem;
}

.choice-card-big img {
    width: 6.25rem;
    margin-bottom: 0.313rem;
}

.choice-card .icon {
    width: 3rem;
    height: 3rem;
}

.choice-card .icon .ui-element {
    stroke: white;
}

.nocodex .choice-card mat-icon {
    font-size: 2rem;
    width: 2rem;
    height: 2rem;
}

.choice-card-big .choice-card-title {
    font-size: 1rem;
    margin-bottom: 0.313rem;
}

.choice-card-big .choice-card-description {
    font-size: 0.75rem;
    line-height: 0.75rem;
}

.choice-card-selected {
    @apply bg-dark-button-background dark:bg-dark-button-background;
}

.choice-card-title {
    @apply text-xxs block leading-3;
}

// Tooltip
// Used in
// - icon picker
// - search filters
.nocodex-tooltip {
    @apply absolute z-10 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm dark:bg-gray-700;
}

// Popover
// Used in
// - icon picker
.popover {
    @apply border-light-border border dark:border-dark-border bg-light-background_level_0 dark:bg-dark-background_level_0 p-3 rounded-lg;
    --tw-bg-opacity: 0.9 !important;
    backdrop-filter: blur(0.25rem);
}

.popover.method-info,
.popover.component-info {
    @apply max-w-[15.625rem];
}

.popover.navigation-info {
    @apply max-w-[25rem];
}

body .ngxp__container {
    z-index: 1000;
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 0;
}

// Title button header
// Used in
// - parameters
// - select parameter (hidden if)
// - search overviews

app-search-header .title-button-header,
codex-plugin-overview-page .title-button-header {
    @apply ml-3 mr-3;
}

.mdc-dialog__content > :first-child.title-button-header,
.title-button-header {
    @apply mt-3 mb-3 flex justify-items-stretch justify-start items-center;
}

.title-button-header-buttons {
    @apply flex justify-end gap-1;
}

// Tag search (OLD MATERIAL STUFF)

.mat-mdc-option:not(.template-grid-panel *):hover {
    @apply bg-dark-button-background #{!important};
    @apply text-white #{!important};
}

.mat-mdc-option:not(.template-grid-panel *):hover.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    @apply text-white #{!important};
}

.latest-chip {
    @apply h-8 bg-light-background_level_2 dark:bg-dark-background_level_2 text-white text-xs px-2.5 py-0.5 rounded-lg dark:text-white flex justify-center items-center;
}

.installed-chip {
    @apply h-8 bg-light-button-background dark:bg-dark-button-background text-white text-xs px-2.5 py-0.5 rounded-lg dark:text-white flex justify-center items-center;
}

codex-tag-input .mat-mdc-form-field-infix,
codex-tag-input .mat-mdc-form-field-flex {
    @apply h-full;
}

codex-tag-input .mat-mdc-text-field-wrapper {
    @apply p-0 #{!important};
}

codex-tag-input .mat-mdc-autocomplete-trigger {
    @apply text-white #{!important};
    @apply caret-white #{!important};
    @apply p-2;
    box-shadow: none !important;
}

codex-tag-input .mat-mdc-text-field-wrapper {
    @apply block w-full p-2
  text-light-input-placeholder_color dark:text-dark-input-placeholder_color
  border border-light-input-border_color dark:border-dark-input-border_color rounded-lg
  bg-light-input-background_color dark:bg-dark-input-background_color
  text-xs
  focus:ring-blue-500
  focus:border-blue-500
  placeholder-light-input-placeholder_color dark:placeholder-dark-input-placeholder_color
  dark:focus:ring-blue-500
  dark:focus:border-blue-500 mb-2;
}

codex-tag-input .mat-mdc-autocomplete-panel {
    @apply block w-full p-2
  text-light-input-placeholder_color dark:text-dark-input-placeholder_color
  placeholder-light-input-placeholder_color dark:placeholder-dark-input-placeholder_color
  border border-light-input-border_color dark:border-dark-input-border_color
  rounded-lg
  bg-light-input-background_color bg-dark-input-background_color
  text-xs
  focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-500
  dark:focus:border-blue-500 mb-2;
}

// Item list
// Used in:
//   - Authorization links
//   - Action links

.alt-item-list {
    @apply flex flex-col
  border border-[#1E293B]
  dark:border-[#1E293B] bg-[#020617]
  dark:bg-[#020617] w-full rounded-lg;
}

.alt-item-list li:not(:first-child) {
    @apply border-t border-[#1E293B];
}

.item-list .item-list-link:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.item-list .item-list-link:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.item-list {
    @apply flex flex-col
  border border-light-border
  dark:border-dark-border bg-light-background_level_0
  dark:bg-dark-background_level_0 w-full rounded-lg;
}

.item-list .item-list-link {
    @apply cursor-pointer;
}

.item-list .item-list-link:hover {
    @apply text-white bg-accent cursor-pointer;
}

.item-list > li:not(:first-child) {
    @apply border-t border-dark-border;
}

.item-list > li,
.alt-item-list li,
li.cdk-drag-preview {
    @apply h-10 flex flex-row justify-center items-center pl-4 pr-4 gap-2;
}

.item-list > li .item-name,
.alt-item-list li .item-name,
li.cdk-drag-preview li .item-name {
    @apply grow shrink text-white;
}

li.cdk-drag-preview {
    @apply bg-light-input-background_color dark:bg-dark-input-background_color rounded-lg border border-light-border dark:border-dark-border justify-start cursor-pointer;
    list-style: none;
    box-sizing: border-box;
    box-shadow:
        0 0.313rem 0.313rem -0.188rem rgba(0, 0, 0, 0.2),
        0 0.5rem 0.625rem 0.063rem rgba(0, 0, 0, 0.14),
        0 0.188rem 0.875rem 0.125rem rgba(0, 0, 0, 0.12);
}

// Workspace list

.workspace-list {
    @apply flex flex-col w-full gap-2;
}

.workspace-list li:hover {
    @apply bg-accent cursor-pointer;
}

.workspace-list li {
    @apply flex flex-row justify-center items-center pl-4 pr-4 py-2 gap-2 rounded-lg;
}

.workspace-list li .workspace-name {
    @apply grow shrink text-white;
}

// Mat tables (OLD MATERIAL STUFF)

.mat-mdc-table:not(template-grid *) {
    @apply border #{!important};
    @apply border-light-border dark:border-dark-border;
}

.mat-mdc-table:not(template-grid *) .mdc-data-table__header-row,
.mat-mdc-table:not(template-grid *) .mdc-data-table__row {
    @apply h-10 #{!important};
}

.mat-mdc-table:not(template-grid *) .mdc-data-table__header-row th {
    @apply border-b border-light-border dark:border-dark-border;
}

.mat-mdc-table:not(template-grid *) .mat-mdc-row:hover,
.mat-mdc-table:not(template-grid *) .mat-mdc-footer-row:hover {
    @apply bg-dark-background_level_0 #{!important};
    @apply text-dark-table-header_font_color #{!important};
}

.mat-mdc-table:not(template-grid *) .mdc-data-table__cell {
    @apply border-b border-light-border dark:border-dark-border;
}

.mat-mdc-table:not(template-grid *) .mdc-data-table__header-row {
    @apply bg-dark-background_level_0 text-dark-table-header_font_color;
}

.mat-mdc-table:not(template-grid *) .mat-mdc-header-cell,
.mat-mdc-table:not(template-grid *) .mdc-data-table__row {
    @apply text-dark-table-header_font_color;
}

.mat-sort-header-arrow:not(template-grid *),
.mat-sort-header-content:not(template-grid *) {
    @apply text-dark-table-header_font_color;
}

.mat-mdc-table:not(template-grid *) .mat-mdc-cell {
    @apply text-white #{!important};
}

.mat-mdc-table:not(template-grid *) .mdc-data-table__row {
    @apply bg-light-background_level_0 dark:bg-dark-background_level_0;
}

// Select searches (OLD MATERIAL STUFF)
mat-drawer-container {
    background-color: transparent !important;
}

.mat-mdc-form-field-type-mat-select:not(template-grid *),
.select-error {
    @apply border border-light-input-border_color dark:border-dark-input-border_color rounded-lg
  bg-light-input-background_color dark:bg-dark-input-background_color
  text-light-input-placeholder_color dark:text-dark-input-placeholder_color
  text-xs focus:ring-blue-500
  focus:border-blue-500 dark:placeholder-gray-400  dark:focus:ring-blue-500;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined:not(template-grid *) .mat-mdc-form-field-infix {
    @apply p-0 #{!important};
}

mat-select:not(template-grid *) {
    @apply text-xs #{!important};
}

.mdc-text-field:not(template-grid *) {
    @apply pl-2 #{!important};
    @apply pr-2 #{!important};
}

.mat-mdc-form-field-type-mat-select:not(template-grid *),
.mat-mdc-form-field-type-mat-select:not(template-grid *) .mat-mdc-text-field-wrapper,
mat-select:not(template-grid *),
mat-form-field:not(template-grid *),
.mat-mdc-autocomplete-panel mat-option:not(template-grid *),
.mat-mdc-select-trigger:not(template-grid *),
.mat-mdc-form-field-infix:not(template-grid *),
.mat-mdc-form-field-flex:not(template-grid *),
.mat-mdc-text-field-wrapper:not(template-grid *) {
    height: 2.125rem !important;
    min-height: unset !important;
}

mat-form-field input:not(template-grid *) {
    height: 2rem !important;
    min-height: unset !important;
    @apply focus:ring-0 #{!important};
}

.mat-select-search-input:not(template-grid *),
mat-form-field input:not(template-grid *) {
    @apply text-light-input-placeholder_color #{!important};
    @apply dark:text-dark-input-placeholder_color #{!important};
    @apply border #{!important};
    @apply border-light-border #{!important};
    @apply dark:border-dark-border #{!important};
    @apply bg-light-input-background_color #{!important};
    @apply dark:bg-dark-input-background_color #{!important};
    @apply dark:placeholder-dark-input-placeholder_color #{!important};
    @apply placeholder-light-input-placeholder_color #{!important};
}

.mat-mdc-select-value-text:not(template-grid *),
.mat-mdc-select-placeholder:not(template-grid *) {
    @apply text-light-input-placeholder_color dark:text-dark-input-placeholder_color;
}

.mdc-notched-outline__leading:not(template-grid *),
.mdc-notched-outline__notch:not(template-grid *),
.mdc-notched-outline__trailing:not(template-grid *) {
    border: none !important;
}

.toolpaneSelect,
.mat-mdc-autocomplete-panel {
    @apply border border-light-border dark:border-dark-border
  bg-light-input-background_color dark:bg-dark-input-background_color text-xs focus:ring-blue-500
  focus:border-blue-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500;
    @apply rounded-lg #{!important};
}

.mat-mdc-autocomplete-panel {
    @apply p-0 #{!important};
}

.mat-mdc-autocomplete-panel mat-option {
    @apply text-xs #{!important};
    @apply hover:bg-dark-button-background #{!important};
    @apply pl-2 pr-2 gap-2;
}

.mat-mdc-autocomplete-panel mat-option mat-icon {
    font-size: 0.875rem;
    height: 0.875rem;
    width: 0.875rem;
    margin-right: 0rem !important;
}

.toolpaneSelect mat-option,
.mat-mdc-autocomplete-panel mat-option,
.toolpaneSelect .mdc-list-item__primary-text,
.mat-mdc-autocomplete-panel .mdc-list-item__primary-text {
    @apply dark:text-white;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--selected):not(template-grid *) .mdc-evolution-chip__action--primary:before {
    @apply bg-light-button-background dark:bg-dark-button-background border-0;
    border: none !important;
}

mat-form-field:not(template-grid *) {
    @apply mb-2 #{!important};
}

// Tabs
// Used in:
// - Editor
// - User management

.alt-tabs-header-wrapper {
    @apply text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-text_2 dark:border-gray-700 bg-dark-background_level_0;
}

.alt-tabs-header {
    @apply flex flex-wrap -mb-px justify-evenly gap-3;
}

.alt-tabs-tab-button {
    @apply pb-3 pt-3 pl-1 pr-1 border-b-2 rounded-t-lg flex gap-1 items-center;
}

.alt-tabs-tab-active {
    @apply text-accent hover:text-accent dark:text-accent dark:hover:text-accent border-accent dark:border-accent;
}

.alt-tabs-tab-active svg use {
    @apply dark:stroke-accent;
}

.alt-tabs-tab-inactive {
    @apply dark:border-transparent hover:text-accent dark:text-dark-tabs-inactive_header_color
  border-[#334155]
  hover:border-[#334155]
  dark:border-[#334155]
  dark:hover:text-accent;
}

.alt-tabs-tab-inactive svg use {
    @apply dark:stroke-dark-tabs-inactive_header_color hover:dark:stroke-accent;
}

.alt-tabs-tab-inactive:hover svg use {
    @apply dark:stroke-accent;
}

.alt-tabs-tab-content {
    @apply p-4;
}

.tabs-header-wrapper {
    @apply border-b border-light-border dark:border-dark-border bg-light-background_level_0 dark:bg-dark-background_level_0;
}

.mini-tabs .tabs-header {
    @apply text-xxs;
}

.tabs-header {
    @apply flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-text_2;
}

.tabs-tab {
    @apply me-2;
}

.tabs-tab-button {
    @apply cursor-pointer p-1.5 m-1.5 rounded-lg hover:text-accenttext hover:bg-white dark:hover:bg-white dark:hover:text-accent flex items-center gap-1;
}

.tabs-tab-active {
    @apply text-accent bg-white;
}

// Drop file
// Used in:
// - create media
.drop-container:not(template-grid *) {
    @apply bg-transparent overflow-hidden border dark:border-dark-input-border_color border-light-input-border_color
  rounded-xl h-[12.5rem] flex justify-center items-center text-center border-dashed flex-col gap-1;
    @apply p-0 #{!important};
    --tw-bg-opacity: 0.9 !important;
    backdrop-filter: blur(0.25rem);
}

.drop-container:not(template-grid *).is-drop-over {
    @apply bg-accentlight;
}

.drop-container:not(template-grid *) h1 {
    flex-grow: 0;
}

.drop-container:not(template-grid *):hover {
    @apply border-dark-button-background;
    backdrop-filter: blur(0.5rem);
}

// Media
.media-preview-container img {
    @apply bg-transparent border dark:border-dark-border border-light-border rounded-xl;
}

// Modals
.swal2-modal {
    @apply bg-light-background_level_0 #{!important};
    @apply dark:bg-dark-background_level_0 #{!important};
}

.swal2-content {
    @apply text-light-input-placeholder_color text-dark-input-placeholder_color;
}

.swal2-icon.animate::before,
.swal2-icon.animate::after,
.swal2-icon.animate .fix {
    @apply bg-dark-background_level_0;
}

mat-dialog-content {
    @apply flex #{!important};
    @apply flex-col;
}

// Design system
.designsystem-colorpicker {
    @apply flex-1 border border-light-border dark:border-dark-border rounded-lg p-1 cursor-pointer;
}

.colorpicker-color {
    @apply h-6 rounded-md mb-2;
}

.designsystem-colorname {
    @apply dark:text-white font-bold text-xs min-w-0 text-nowrap overflow-hidden text-ellipsis;
}

.designsystem-colorvalue {
    @apply dark:text-white font-bold m-0 text-xxs;
    @apply mb-0 #{!important};
}

.designsystem-colorgrid {
    @apply w-full grid grid-cols-12 gap-1 max-w-[37.5rem];
}

.designsystem-colorpicker-popup {
    @apply border rounded-lg border-light-border dark:border-dark-border bg-light-background_level_0 dark:bg-dark-background_level_0;
}

//Package

.package-card {
    @apply bg-light-background_level_0 dark:bg-dark-background_level_0 text-white p-4 border border-light-border dark:border-dark-border rounded-lg;
}

.billing-card {
    @apply bg-light-background_level_0 dark:bg-dark-background_level_0 text-white p-4 border border-light-border dark:border-dark-border rounded-lg;
}

// Home

.home-block {
    @apply p-4 rounded-lg border border-light-border dark:border-dark-border grow shrink bg-light-background_level_0 dark:bg-dark-background_level_0;
    flex: 1 1;
}

.home-block h1 {
    @apply text-white;
}

.home-block p {
    color: #cbd5e1;
}

// Tiny MCE
.tox {
    z-index: 1000;
}

// Zoom controls

.zoomcontrols {
    @apply flex flex-row items-center justify-center cursor-pointer;
    @apply text-sm #{!important};
    @apply h-5 #{!important};
    @apply text-dark-icons-inactive_color;
}

// Messages
.warning-message {
    @apply p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-light-background_level_1 dark:text-yellow-300 border border-light-border dark:border-dark-border flex flex-row gap-1;
}

.warning-message svg use.ui-element {
    @apply stroke-yellow-300;
}

// URL list
// Used in:
// - Template information
// - Api edit form

.url-list {
    display: block;
    font-size: 0.625rem;
    list-style: none;
    padding: 0;
    margin: 0;
}

.url-list mat-icon {
    font-size: 0.938rem;
    height: 1.125rem;
    width: 1.438rem;
}

.url-list button {
    height: 1.563rem;
    width: 1.563rem;
    padding: 0.125rem;
}

.url-list li {
    height: 1.563rem;
    color: #9a9a9a;
    @apply gap-1 flex items-center;
}

// Documentation

.http-method {
    @apply rounded-lg bg-accent text-white font-bold p-1;
}

markdown a {
    text-decoration: underline;
    cursor: pointer;
}

markdown a::before {
    content: '';
    display: inline-block;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1007_10305)'%3E%3Cpath d='M8.33335 10.8332C8.69122 11.3117 9.14781 11.7075 9.67214 11.994C10.1965 12.2805 10.7763 12.4508 11.3722 12.4935C11.9682 12.5362 12.5663 12.4502 13.1261 12.2414C13.6859 12.0326 14.1943 11.7058 14.6167 11.2832L17.1167 8.78322C17.8757 7.99738 18.2956 6.94487 18.2862 5.85238C18.2767 4.7599 17.8385 3.71485 17.0659 2.94231C16.2934 2.16978 15.2483 1.73157 14.1559 1.72208C13.0634 1.71259 12.0109 2.13256 11.225 2.89156L9.79168 4.31656M11.6667 9.16656C11.3088 8.68812 10.8522 8.29224 10.3279 8.00577C9.80356 7.71931 9.22376 7.54896 8.6278 7.50628C8.03185 7.4636 7.43369 7.54958 6.87389 7.75841C6.31409 7.96723 5.80575 8.294 5.38335 8.71656L2.88335 11.2166C2.12436 12.0024 1.70438 13.0549 1.71387 14.1474C1.72337 15.2399 2.16157 16.2849 2.9341 17.0575C3.70664 17.83 4.75169 18.2682 5.84418 18.2777C6.93666 18.2872 7.98917 17.8672 8.77501 17.1082L10.2 15.6832' stroke='white' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1007_10305'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    width: 1rem;
    height: 1rem;
    margin-right: 0.313rem;
    vertical-align: middle;
    background-size: contain;
    background-repeat: no-repeat;
}

markdown a:hover {
    @apply text-accent;
}

markdown p {
    font-size: 0.9rem;
}

markdown h1,
markdown h2,
markdown h3,
markdown h4,
markdown h5,
markdown h6 {
    font-weight: bold !important;
}

markdown ul {
    list-style: disc;
    padding-left: 1.25rem;
}

markdown h1 {
    margin-bottom: 1.25rem !important;
    margin-top: 0.625rem !important;
}

markdown h2 {
    margin-bottom: 0.625rem !important;
    margin-top: 0.313rem !important;
}

markdown h3,
markdown h4 {
    margin-bottom: 0.625rem !important;
    margin-top: 0.313rem !important;
}

markdown pre {
    @apply bg-light-background_level_1 dark:bg-dark-background_level_1 rounded-lg border border-light-border dark:border-dark-border;
    overflow: auto;
    margin: 0 0 1em;
    padding: 0.5em 1em;
}

markdown pre code,
markdown pre .line-number {
    /* Ukuran line-height antara teks di dalam tag <code> dan <span class="line-number"> harus sama! */
    font:
        normal normal 0.75rem/0.875rem 'Courier New',
        Courier,
        Monospace;
    color: black;
    display: block;
}

markdown pre .line-number {
    float: left;
    margin: 0 1em 0 -1em;
    border-right: 0.063rem solid;
    text-align: right;
}

markdown pre .line-number span {
    display: block;
    padding: 0 0.5em 0 1em;
}

markdown pre .cl {
    display: block;
    clear: both;
}

// Generative message

.generative-message {
    @apply w-full mb-2 flex gap-2 items-end;
    flex-direction: column;
}

.generative-assistant-no-ai-credits-overlay {
    @apply absolute h-full w-full left-0 top-[45px] flex flex-col justify-center items-center gap-4 text-center;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    z-index: 10000;
}

.generative-message-sender-name {
    @apply text-xs text-white font-bold text-right;
}

.generative-message markdown p {
    @apply mb-1 mt-1;
}

.generative-message markdown p,
.generative-message markdown ol {
    @apply text-xs;
}

.generative-message-text {
    @apply text-xs bg-light-background_level_0 p-2 rounded-lg w-fit border-dark-border border;
    color: #cbd5e1;
    word-break: auto-phrase;
}
.generative-message-quality-overlay {
    position: absolute;
    right: 0;
    padding-top: 25px;
    padding-left: 5px;
    padding-right: 5px;
    z-index: 10;
    @apply text-xs bg-light-background_level_0 rounded-lg border-dark-border border w-[95%];
    color: #cbd5e1;
    padding-bottom: 5px;
}

.generative-message-quality-overlay mat-slider {
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 20px);
}

.tokens {
    min-width: 200px;
}

.generative-container {
    @apply p-2 h-full rounded-lg border border-light-border dark:border-dark-border grow shrink bg-light-background_level_0 dark:bg-dark-background_level_0;
    --tw-bg-opacity: 0.9 !important;
    backdrop-filter: blur(0.25rem);
}
.generative-header {
    @apply flex justify-between items-center;
}
.generative-messages {
    @apply grow flex-col flex h-full relative;
}

.generative-credits {
    @apply bg-light-background_level_0 border-dark-border border rounded-lg p-2 text-xs self-end flex gap-1 justify-center items-center;
}

.generative-chat-messages {
    @apply scrollbar scrollbar-primary overflow-x-hidden flex flex-col grow mt-2 mb-2;
}

.right-panel {
    right: -100%;
    top: 0;
    opacity: 0;
    visibility: hidden;
    position: absolute;
}

#generative-chat {
    @apply w-[400px];
    top: 96px;
    height: calc(100vh - 96px);
}

.dashboard #generative-chat {
    top: 47px;
    height: calc(100vh - 47px);
}

.right-panel.show {
    opacity: 1;
    visibility: visible;
    z-index: 10;
    @apply top-0 right-0;
}

.generative-actions {
    @apply flex flex-row gap-1 flex-wrap mb-2;
}

.generative-action {
    @apply flex flex-row gap-1 bg-light-background_level_0 border-dark-border border rounded-lg p-1 text-xxs items-center cursor-pointer;
}

.generative-action:hover {
    @apply border-accent text-accent;
}

.generative-action:hover .icon use.ui-element {
    @apply stroke-accent;
}

.generative-action .icon {
    height: 1rem;
    width: 1rem;
}

.generating {
    position: relative;
}

.generating::before {
    content: '';
    position: absolute;
    top: 5px;
    left: -20px;
    right: -20px;
    bottom: 5px;
    background: linear-gradient(90deg, #c4acff, #5f00bc);
    border-radius: 4px;
    z-index: -1;
    animation: rotate 2s linear infinite;
}

.generative-message-text-wrapper {
    @apply overflow-hidden p-[1px] mt-2 max-w-[85%] rounded-lg;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.mat-mdc-icon-button {
    cursor: pointer;
}

.mat-mdc-icon-button:not(template-grid *):hover {
    @apply bg-dark-button-background;
}

.mat-mdc-icon-button[aria-disabled] .mat-mdc-paginator-icon {
    fill: white;
}

.mat-mdc-select-arrow {
    color: white;
}

.monaco-editor {
    --vscode-focusBorder: #9c38ff !important;
    border-radius: 8px;
}

.monaco-editor .overflow-guard {
    border-radius: 8px;
}

chrome-picker .column input {
    @apply block w-full p-2
  border
  text-white dark:text-white
  rounded-lg
  bg-dark-input-background_color
  border-dark-input-border_color
  text-xs focus:ring-light-button-background
  focus:border-light-button-border_color dark:placeholder-gray-400 dark:focus:ring-light-button-background
  dark:focus:border-light-button-border_color mb-2 h-[2.125rem];
}

chrome-picker .type-btn {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.83337 12.5L10 16.6667L14.1667 12.5M5.83337 7.50004L10 3.33337L14.1667 7.50004' stroke='%2394a3b8' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important;
    cursor: pointer;
}

chrome-picker .type-btn:hover {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.83337 12.5L10 16.6667L14.1667 12.5M5.83337 7.50004L10 3.33337L14.1667 7.50004' stroke='%239C38FF' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
    background-color: transparent !important;
}

codex-application-overview .node-label {
    @apply text-xs text-center;
}

codex-application-overview .node-container {
    @apply border
  hover:bg-accentlight dark:hover:bg-accentlight text-gray-900 dark:text-white rounded-lg h-full
  flex justify-between items-center p-4 gap-2 flex-col;
    border: 0.063rem solid rgba(128, 0, 255, 0.75);
    background-color: #0f172a;
    height: calc(100% - 0.938rem);
    margin: 0.438rem;
}

#console-editor.action-editor {
    overflow: visible;
}

#console-editor.template-editor,
#console-editor.template-editor #editor-tab-content {
    overflow: visible;
}

.application-overview-task {
    @apply p-2 rounded-lg border border-light-border dark:border-dark-border grow shrink bg-light-background_level_0 dark:bg-dark-background_level_0 w-[250px] grow-0;
}

.application-overview-node {
    @apply p-2 rounded-lg border border-light-border dark:border-dark-border grow shrink bg-light-background_level_0 dark:bg-dark-background_level_0 w-[500px];
}

.application-overview-node.selected {
    @apply dark:border-accent;
}

.application-overview-node .generative-container {
    border: none;
}
