template-grid {
    .reset .part-content {
        --part-border-left-radius: inherit;
        --part-border-left-color: inherit;
        --part-border-left-type: inherit;
        --part-border-left-width: inherit;
        --part-border-right-radius: inherit;
        --part-border-right-color: inherit;
        --part-border-right-type: inherit;
        --part-border-right-width: inherit;
        --part-border-top-radius: inherit;
        --part-border-top-color: inherit;
        --part-border-top-type: inherit;
        --part-border-top-width: inherit;
        --part-border-bottom-radius: inherit;
        --part-border-bottom-color: inherit;
        --part-border-bottom-type: inherit;
        --part-border-bottom-width: inherit;
        --part-border-radius: inherit;
        --part-border-color: inherit;
        --part-border-type: inherit;
        --part-border-width: inherit;
        --part-padding-top: inherit;
        --part-padding-right: inherit;
        --part-padding-bottom: inherit;
        --part-padding-left: inherit;
        --part-padding: inherit;
        --part-background-color: inherit;
        --part-background-image: inherit;
        --part-background-attachment: inherit;
        --part-background-position: inherit;
        --part-background-repeat: inherit;
        --part-background-size: inherit;
        --part-background-origin: inherit;
        --part-box-shadow: inherit;
    }

    // GENERAL
    .part-content {
        height: 100%;
        width: 100%;

        border-top-left-radius: var(--part-border-left-radius, var(--part-border-top-left-radius, var(--part-border-radius)));
        border-bottom-left-radius: var(--part-border-left-radius, var(--part-border-bottom-left-radius, var(--part-border-radius)));
        border-left-color: var(--part-border-left-color, var(--part-border-color));
        border-left-style: var(--part-border-left-style, var(--part-border-style));
        border-left-width: var(--part-border-left-width, var(--part-border-width));

        border-top-right-radius: var(--part-border-right-radius, var(--part-border-top-right-radius, var(--part-border-radius)));
        border-bottom-right-radius: var(--part-border-right-radius, var(--part-border-bottom-right-radius, var(--part-border-radius)));
        border-right-color: var(--part-border-right-color, var(--part-border-color));
        border-right-style: var(--part-border-right-style, var(--part-border-style));
        border-right-width: var(--part-border-right-width, var(--part-border-width));

        border-top-left-radius: var(--part-border-top-radius, var(--part-border-top-left-radius, var(--part-border-radius)));
        border-top-right-radius: var(--part-border-top-radius, var(--part-border-top-right-radius, var(--part-border-radius)));
        border-top-color: var(--part-border-top-color, var(--part-border-color));
        border-top-style: var(--part-border-top-style, var(--part-border-style));
        border-top-width: var(--part-border-top-width, var(--part-border-width));

        border-bottom-left-radius: var(--part-border-bottom-radius, var(--part-border-bottom-left-radius, var(--part-border-radius)));
        border-bottom-right-radius: var(--part-border-bottom-radius, var(--part-border-bottom-right-radius, var(--part-border-radius)));
        border-bottom-color: var(--part-border-bottom-color, var(--part-border-color));
        border-bottom-style: var(--part-border-bottom-style, var(--part-border-style));
        border-bottom-width: var(--part-border-bottom-width, var(--part-border-width));

        padding-top: var(--part-padding-top, var(--part-padding));
        padding-right: var(--part-padding-right, var(--part-padding));
        padding-bottom: var(--part-padding-bottom, var(--part-padding));
        padding-left: var(--part-padding-left, var(--part-padding));

        margin-top: var(--part-margin-top, var(--part-margin));
        margin-right: var(--part-margin-right, var(--part-margin));
        margin-bottom: var(--part-margin-bottom, var(--part-margin));
        margin-left: var(--part-margin-left, var(--part-margin));

        background-color: var(--part-background-color);
        background-clip: var(--part-background-clip);
        background-image: var(--part-background-image);
        background-attachment: var(--part-background-attachment);
        background-position: var(--part-background-position);
        background-repeat: var(--part-background-repeat);
        background-size: var(--part-background-size);
        background-origin: var(--part-background-origin);

        box-shadow: var(--part-box-shadow);

        opacity: var(--part-opacity);
    }

    .part-content:hover {
        height: 100%;
        width: 100%;

        border-top-left-radius: var(
            --part-hover-border-left-radius,
            var(--part-hover-border-radius, var(--part-border-left-radius, var(--part-border-radius)))
        );
        border-bottom-left-radius: var(
            --part-hover-border-left-radius,
            var(--part-hover-border-radius, var(--part-border-left-radius, var(--part-border-radius)))
        );
        border-left-color: var(
            --part-hover-border-left-color,
            var(--part-hover-border-color, var(--part-border-left-color, var(--part-border-color)))
        );
        border-left-style: var(
            --part-hover-border-left-style,
            var(--part-hover-border-style, var(--part-border-left-style, var(--part-border-style)))
        );
        border-left-width: var(
            --part-hover-border-left-width,
            var(--part-hover-border-width, var(--part-border-left-width, var(--part-border-width)))
        );

        border-top-right-radius: var(
            --part-hover-border-right-radius,
            var(--part-hover-border-radius, var(--part-border-right-radius, var(--part-border-radius)))
        );
        border-bottom-right-radius: var(
            --part-hover-border-right-radius,
            var(--part-hover-border-radius, var(--part-border-right-radius, var(--part-border-radius)))
        );
        border-right-color: var(
            --part-hover-border-right-color,
            var(--part-hover-border-color, var(--part-border-right-color, var(--part-border-color)))
        );
        border-right-style: var(
            --part-hover-border-right-style,
            var(--part-hover-border-style, var(--part-border-right-style, var(--part-border-style)))
        );
        border-right-width: var(
            --part-hover-border-right-width,
            var(--part-hover-border-width, var(--part-border-right-width, var(--part-border-width)))
        );

        border-top-left-radius: var(
            --part-hover-border-top-radius,
            var(--part-hover-border-radius, var(--part-border-top-radius, var(--part-border-radius)))
        );
        border-top-right-radius: var(
            --part-hover-border-top-radius,
            var(--part-hover-border-radius, var(--part-border-top-radius, var(--part-border-radius)))
        );
        border-top-color: var(
            --part-hover-border-top-color,
            var(--part-hover-border-color, var(--part-border-top-color, var(--part-border-color)))
        );
        border-top-style: var(
            --part-hover-border-top-style,
            var(--part-hover-border-style, var(--part-border-top-style, var(--part-border-style)))
        );
        border-top-width: var(
            --part-hover-border-top-width,
            var(--part-hover-border-width, var(--part-border-top-width, var(--part-border-width)))
        );

        border-bottom-left-radius: var(
            --part-hover-border-bottom-radius,
            var(--part-hover-border-radius, var(--part-border-bottom-radius, var(--part-border-radius)))
        );
        border-bottom-right-radius: var(
            --part-hover-border-bottom-radius,
            var(--part-hover-border-radius, var(--part-border-bottom-radius, var(--part-border-radius)))
        );
        border-bottom-color: var(
            --part-hover-border-bottom-color,
            var(--part-hover-border-color, var(--part-border-bottom-color, var(--part-border-color)))
        );
        border-bottom-style: var(
            --part-hover-border-bottom-style,
            var(--part-hover-border-style, var(--part-border-bottom-style, var(--part-border-style)))
        );
        border-bottom-width: var(
            --part-hover-border-bottom-width,
            var(--part-hover-border-width, var(--part-border-bottom-width, var(--part-border-width)))
        );

        background-color: var(--part-hover-background-color, var(--part-background-color));
        background-clip: var(--part-hover-background-clip, var(--part-background-clip));
        background-image: var(--part-hover-background-image, var(--part-background-image));
        background-attachment: var(--part-hover-background-attachment, var(--part-background-attachment));
        background-position: var(--part-hover-background-position, var(--part-background-position));
        background-repeat: var(--part-hover-background-repeat, var(--part-background-repeat));
        background-size: var(--part-hover-background-size, var(--part-background-size));
        background-origin: var(--part-hover-background-origin, var(--part-background-origin));

        padding-top: var(--part-hover-padding-top, var(--part-hover-padding, var(--part-padding-top, var(--part-padding))));
        padding-right: var(--part-hover-padding-right, var(--part-hover-padding, var(--part-padding-right, var(--part-padding))));
        padding-bottom: var(--part-hover-padding-bottom, var(--part-hover-padding, var(--part-padding-bottom, var(--part-padding))));
        padding-left: var(--part-hover-padding-left, var(--part-hover-padding, var(--part-padding-left, var(--part-padding))));

        margin-top: var(--part-hover-margin-top, var(--part-hover-margin, var(--part-margin-top, var(--part-margin))));
        margin-right: var(--part-hover-margin-right, var(--part-hover-margin, var(--part-margin-right, var(--part-margin))));
        margin-bottom: var(--part-hover-margin-bottom, var(--part-hover-margin, var(--part-margin-bottom, var(--part-margin))));
        margin-left: var(--part-hover-margin-left, var(--part-hover-margin, var(--part-margin-left, var(--part-margin))));

        box-shadow: var(--part-hover-box-shadow, var(--part-box-shadow));

        opacity: var(--part-hover-opacity, var(--part-opacity));
    }

    .part-content:focus {
        height: 100%;
        width: 100%;

        border-top-left-radius: var(
            --part-focus-border-left-radius,
            var(--part-focus-border-radius, var(--part-border-left-radius, var(--part-border-radius)))
        );
        border-bottom-left-radius: var(
            --part-focus-border-left-radius,
            var(--part-focus-border-radius, var(--part-border-left-radius, var(--part-border-radius)))
        );
        border-left-color: var(
            --part-focus-border-left-color,
            var(--part-focus-border-color, var(--part-border-left-color, var(--part-border-color)))
        );
        border-left-style: var(
            --part-focus-border-left-style,
            var(--part-focus-border-style, var(--part-border-left-style, var(--part-border-style)))
        );
        border-left-width: var(
            --part-focus-border-left-width,
            var(--part-focus-border-width, var(--part-border-left-width, var(--part-border-width)))
        );

        border-top-right-radius: var(
            --part-focus-border-right-radius,
            var(--part-focus-border-radius, var(--part-border-right-radius, var(--part-border-radius)))
        );
        border-bottom-right-radius: var(
            --part-focus-border-right-radius,
            var(--part-focus-border-radius, var(--part-border-right-radius, var(--part-border-radius)))
        );
        border-right-color: var(
            --part-focus-border-right-color,
            var(--part-focus-border-color, var(--part-border-right-color, var(--part-border-color)))
        );
        border-right-style: var(
            --part-focus-border-right-style,
            var(--part-focus-border-style, var(--part-border-right-style, var(--part-border-style)))
        );
        border-right-width: var(
            --part-focus-border-right-width,
            var(--part-focus-border-width, var(--part-border-right-width, var(--part-border-width)))
        );

        border-top-left-radius: var(
            --part-focus-border-top-radius,
            var(--part-focus-border-radius, var(--part-border-top-radius, var(--part-border-radius)))
        );
        border-top-right-radius: var(
            --part-focus-border-top-radius,
            var(--part-focus-border-radius, var(--part-border-top-radius, var(--part-border-radius)))
        );
        border-top-color: var(
            --part-focus-border-top-color,
            var(--part-focus-border-color, var(--part-border-top-color, var(--part-border-color)))
        );
        border-top-style: var(
            --part-focus-border-top-style,
            var(--part-focus-border-style, var(--part-border-top-style, var(--part-border-style)))
        );
        border-top-width: var(
            --part-focus-border-top-width,
            var(--part-focus-border-width, var(--part-border-top-width, var(--part-border-width)))
        );

        border-bottom-left-radius: var(
            --part-focus-border-bottom-radius,
            var(--part-focus-border-radius, var(--part-border-bottom-radius, var(--part-border-radius)))
        );
        border-bottom-right-radius: var(
            --part-focus-border-bottom-radius,
            var(--part-focus-border-radius, var(--part-border-bottom-radius, var(--part-border-radius)))
        );
        border-bottom-color: var(
            --part-focus-border-bottom-color,
            var(--part-focus-border-color, var(--part-border-bottom-color, var(--part-border-color)))
        );
        border-bottom-style: var(
            --part-focus-border-bottom-style,
            var(--part-focus-border-style, var(--part-border-bottom-style, var(--part-border-style)))
        );
        border-bottom-width: var(
            --part-focus-border-bottom-width,
            var(--part-focus-border-width, var(--part-border-bottom-width, var(--part-border-width)))
        );

        background-color: var(--part-focus-background-color, var(--part-background-color));
        background-clip: var(--part-focus-background-clip, var(--part-background-clip));
        background-image: var(--part-focus-background-image, var(--part-background-image));
        background-attachment: var(--part-focus-background-attachment, var(--part-background-attachment));
        background-position: var(--part-focus-background-position, var(--part-background-position));
        background-repeat: var(--part-focus-background-repeat, var(--part-background-repeat));
        background-size: var(--part-focus-background-size, var(--part-background-size));
        background-origin: var(--part-focus-background-origin, var(--part-background-origin));

        padding-top: var(--part-focus-padding-top, var(--part-focus-padding, var(--part-padding-top, var(--part-padding))));
        padding-right: var(--part-focus-padding-right, var(--part-focus-padding, var(--part-padding-right, var(--part-padding))));
        padding-bottom: var(--part-focus-padding-bottom, var(--part-focus-padding, var(--part-padding-bottom, var(--part-padding))));
        padding-left: var(--part-focus-padding-left, var(--part-focus-padding, var(--part-padding-left, var(--part-padding))));

        margin-top: var(--part-focus-margin-top, var(--part-focus-margin, var(--part-margin-top, var(--part-margin))));
        margin-right: var(--part-focus-margin-right, var(--part-focus-margin, var(--part-margin-right, var(--part-margin))));
        margin-bottom: var(--part-focus-margin-bottom, var(--part-focus-margin, var(--part-margin-bottom, var(--part-margin))));
        margin-left: var(--part-focus-margin-left, var(--part-focus-margin, var(--part-margin-left, var(--part-margin))));

        box-shadow: var(--part-focus-box-shadow, var(--part-box-shadow));

        opacity: var(--part-focus-opacity, var(--part-opacity));
    }

    .part-content:focus-within {
        height: 100%;
        width: 100%;

        border-top-left-radius: var(
            --part-focus-within-border-left-radius,
            var(--part-focus-within-border-radius, var(--part-border-left-radius, var(--part-border-radius)))
        );
        border-bottom-left-radius: var(
            --part-focus-within-border-left-radius,
            var(--part-focus-within-border-radius, var(--part-border-left-radius, var(--part-border-radius)))
        );
        border-left-color: var(
            --part-focus-within-border-left-color,
            var(--part-focus-within-border-color, var(--part-border-left-color, var(--part-border-color)))
        );
        border-left-style: var(
            --part-focus-within-border-left-style,
            var(--part-focus-within-border-style, var(--part-border-left-style, var(--part-border-style)))
        );
        border-left-width: var(
            --part-focus-within-border-left-width,
            var(--part-focus-within-border-width, var(--part-border-left-width, var(--part-border-width)))
        );

        border-top-right-radius: var(
            --part-focus-within-border-right-radius,
            var(--part-focus-within-border-radius, var(--part-border-right-radius, var(--part-border-radius)))
        );
        border-bottom-right-radius: var(
            --part-focus-within-border-right-radius,
            var(--part-focus-within-border-radius, var(--part-border-right-radius, var(--part-border-radius)))
        );
        border-right-color: var(
            --part-focus-within-border-right-color,
            var(--part-focus-within-border-color, var(--part-border-right-color, var(--part-border-color)))
        );
        border-right-style: var(
            --part-focus-within-border-right-style,
            var(--part-focus-within-border-style, var(--part-border-right-style, var(--part-border-style)))
        );
        border-right-width: var(
            --part-focus-within-border-right-width,
            var(--part-focus-within-border-width, var(--part-border-right-width, var(--part-border-width)))
        );

        border-top-left-radius: var(
            --part-focus-within-border-top-radius,
            var(--part-focus-within-border-radius, var(--part-border-top-radius, var(--part-border-radius)))
        );
        border-top-right-radius: var(
            --part-focus-within-border-top-radius,
            var(--part-focus-within-border-radius, var(--part-border-top-radius, var(--part-border-radius)))
        );
        border-top-color: var(
            --part-focus-within-border-top-color,
            var(--part-focus-within-border-color, var(--part-border-top-color, var(--part-border-color)))
        );
        border-top-style: var(
            --part-focus-within-border-top-style,
            var(--part-focus-within-border-style, var(--part-border-top-style, var(--part-border-style)))
        );
        border-top-width: var(
            --part-focus-within-border-top-width,
            var(--part-focus-within-border-width, var(--part-border-top-width, var(--part-border-width)))
        );

        border-bottom-left-radius: var(
            --part-focus-within-border-bottom-radius,
            var(--part-focus-within-border-radius, var(--part-border-bottom-radius, var(--part-border-radius)))
        );
        border-bottom-right-radius: var(
            --part-focus-within-border-bottom-radius,
            var(--part-focus-within-border-radius, var(--part-border-bottom-radius, var(--part-border-radius)))
        );
        border-bottom-color: var(
            --part-focus-within-border-bottom-color,
            var(--part-focus-within-border-color, var(--part-border-bottom-color, var(--part-border-color)))
        );
        border-bottom-style: var(
            --part-focus-within-border-bottom-style,
            var(--part-focus-within-border-style, var(--part-border-bottom-style, var(--part-border-style)))
        );
        border-bottom-width: var(
            --part-focus-within-border-bottom-width,
            var(--part-focus-within-border-width, var(--part-border-bottom-width, var(--part-border-width)))
        );

        background-color: var(--part-focus-within-background-color, var(--part-background-color));
        background-clip: var(--part-focus-within-background-clip, var(--part-background-clip));
        background-image: var(--part-focus-within-background-image, var(--part-background-image));
        background-attachment: var(--part-focus-within-background-attachment, var(--part-background-attachment));
        background-position: var(--part-focus-within-background-position, var(--part-background-position));
        background-repeat: var(--part-focus-within-background-repeat, var(--part-background-repeat));
        background-size: var(--part-focus-within-background-size, var(--part-background-size));
        background-origin: var(--part-focus-within-background-origin, var(--part-background-origin));

        padding-top: var(--part-focus-within-padding-top, var(--part-focus-within-padding, var(--part-padding-top, var(--part-padding))));
        padding-right: var(
            --part-focus-within-padding-right,
            var(--part-focus-within-padding, var(--part-padding-right, var(--part-padding)))
        );
        padding-bottom: var(
            --part-focus-within-padding-bottom,
            var(--part-focus-within-padding, var(--part-padding-bottom, var(--part-padding)))
        );
        padding-left: var(
            --part-focus-within-padding-left,
            var(--part-focus-within-padding, var(--part-padding-left, var(--part-padding)))
        );

        box-shadow: var(--part-focus-within-box-shadow, var(--part-box-shadow));

        opacity: var(--part-focus-within-opacity, var(--part-opacity));
    }

    // Image
    img {
        object-fit: var(--image-object-fit);
    }

    // ------ Form parts ------

    // File Uploader
    .file-uploader {
        .file {
            // BORDER
            border-top-left-radius: var(--uploader-file-border-left-radius);
            border-bottom-left-radius: var(--uploader-file-border-left-radius);
            border-left-color: var(--uploader-file-border-left-color, var(--uploader-file-border-color));
            border-left-style: var(--uploader-file-border-left-style, var(--uploader-file-border-style));
            border-left-width: var(--uploader-file-border-left-width, var(--uploader-file-border-width));

            border-top-right-radius: var(--uploader-file-border-right-radius);
            border-bottom-right-radius: var(--uploader-file-border-right-radius);
            border-right-color: var(--uploader-file-border-right-color, var(--uploader-file-border-color));
            border-right-style: var(--uploader-file-border-right-style, var(--uploader-file-border-style));
            border-right-width: var(--uploader-file-border-right-width, var(--uploader-file-border-width));

            border-top-left-radius: var(--uploader-file-border-top-radius);
            border-top-right-radius: var(--uploader-file-border-top-radius);
            border-top-color: var(--uploader-file-border-top-color, var(--uploader-file-border-color));
            border-top-style: var(--uploader-file-border-top-style, var(--uploader-file-border-style));
            border-top-width: var(--uploader-file-border-top-width, var(--uploader-file-border-width));

            border-bottom-left-radius: var(--uploader-file-border-bottom-radius);
            border-bottom-right-radius: var(--uploader-file-border-bottom-radius);
            border-bottom-color: var(--uploader-file-border-bottom-color, var(--uploader-file-border-color));
            border-bottom-style: var(--uploader-file-border-bottom-style, var(--uploader-file-border-style));
            border-bottom-width: var(--uploader-file-border-bottom-width, var(--uploader-file-border-width));

            // MARGIN
            margin-top: var(--uploader-file-margin-top, var(--uploader-file-margin));
            margin-right: var(--uploader-file-margin-right, var(--uploader-file-margin));
            margin-bottom: var(--uploader-file-margin-bottom, var(--uploader-file-margin));
            margin-left: var(--uploader-file-margin-left, var(--uploader-file-margin));

            // PADDING
            padding-top: var(--uploader-file-padding-top, var(--uploader-file-padding));
            padding-right: var(--uploader-file-padding-right, var(--uploader-file-padding));
            padding-bottom: var(--uploader-file-padding-bottom, var(--uploader-file-padding));
            padding-left: var(--uploader-file-padding-left, var(--uploader-file-padding));

            // FONT
            font-family: var(--uploader-file-font-family) !important;
            line-height: var(--uploader-file-line-height) !important;
            font-size: var(--uploader-file-font-size) !important;
            letter-spacing: var(--uploader-file-letter-spacing) !important;
            font-weight: var(--uploader-file-font-weight) !important;
            font-stretch: var(--uploader-file-font-stretch) !important;
            font-style: var(--uploader-file-font-style) !important;
            font-variant: var(--uploader-file-font-variant) !important;
            font-variant-caps: var(--uploader-file-font-variant-caps) !important;
            text-align: var(--uploader-file-font-alignment) !important;
            color: var(--uploader-file-font-color) !important;

            // BACKGROUND
            background-color: var(--uploader-file-background-color);

            &:hover {
                // BORDER
                border-top-left-radius: var(--uploader-file-hover-border-left-radius, var(--uploader-file-border-left-radius));
                border-bottom-left-radius: var(--uploader-file-border-left-radius, var(--uploader-file-border-left-radius));
                border-left-color: var(
                    --uploader-file-hover-border-left-color,
                    var(--uploader-file-hover-border-color, var(--uploader-file-border-left-color, var(--uploader-file-border-color)))
                );
                border-left-style: var(
                    --uploader-file-hover-border-left-style,
                    var(--uploader-file-hover-border-style, var(--uploader-file-border-left-style, var(--uploader-file-border-style)))
                );
                border-left-width: var(
                    --uploader-file-hover-border-left-width,
                    var(--uploader-file-hover-border-width, var(--uploader-file-border-left-width, var(--uploader-file-border-width)))
                );

                border-top-right-radius: var(--uploader-file-hover-border-right-radius, var(--uploader-file-border-right-radius));
                border-bottom-right-radius: var(--uploader-file-hover-border-right-radius, var(--uploader-file-border-right-radius));
                border-right-color: var(
                    --uploader-file-hover-border-right-color,
                    var(--uploader-file-hover-border-color, var(--uploader-file-border-right-color, var(--uploader-file-border-color)))
                );
                border-right-style: var(
                    --uploader-file-hover-border-right-style,
                    var(--uploader-file-hover-border-style, var(--uploader-file-border-right-style, var(--uploader-file-border-style)))
                );
                border-right-width: var(
                    --uploader-file-hover-border-right-width,
                    var(--uploader-file-hover-border-width, var(--uploader-file-border-right-width, var(--uploader-file-border-width)))
                );

                border-top-left-radius: var(--uploader-file-hover-border-top-radius, var(--uploader-file-border-top-radius));
                border-top-right-radius: var(--uploader-file-hover-border-top-radius, var(--uploader-file-border-top-radius));
                border-top-color: var(
                    --uploader-file-hover-border-top-color,
                    var(--uploader-file-hover-border-color, var(--uploader-file-border-top-color, var(--uploader-file-border-color)))
                );
                border-top-style: var(
                    --uploader-file-hover-border-top-style,
                    var(--uploader-file-hover-border-style, var(--uploader-file-border-top-style, var(--uploader-file-border-style)))
                );
                border-top-width: var(
                    --uploader-file-hover-border-top-width,
                    var(--uploader-file-hover-border-width, var(--uploader-file-border-top-width, var(--uploader-file-border-width)))
                );

                border-bottom-left-radius: var(--uploader-file-hover-border-bottom-radius, var(--uploader-file-border-bottom-radius));
                border-bottom-right-radius: var(--uploader-file-hover-border-bottom-radius, var(--uploader-file-border-bottom-radius));
                border-bottom-color: var(
                    --uploader-file-hover-border-bottom-color,
                    var(--uploader-file-hover-border-color, var(--uploader-file-border-bottom-color, var(--uploader-file-border-color)))
                );
                border-bottom-style: var(
                    --uploader-file-hover-border-bottom-style,
                    var(--uploader-file-hover-border-style, var(--uploader-file-border-bottom-style, var(--uploader-file-border-style)))
                );
                border-bottom-width: var(
                    --uploader-file-hover-border-bottom-width,
                    var(--uploader-file-hover-border-width, var(--uploader-file-border-bottom-width, var(--uploader-file-border-width)))
                );

                // MARGIN
                margin-top: var(
                    --uploader-file-hover-margin-top,
                    var(--uploader-file-hover-margin, var(--uploader-file-margin-top, var(--uploader-file-margin)))
                );
                margin-right: var(
                    --uploader-file-hover-margin-right,
                    var(--uploader-file-hover-margin, var(--uploader-file-margin-right, var(--uploader-file-margin)))
                );
                margin-bottom: var(
                    --uploader-file-hover-margin-bottom,
                    var(--uploader-file-hover-margin, var(--uploader-file-margin-bottom, var(--uploader-file-margin)))
                );
                margin-left: var(
                    --uploader-file-hover-margin-left,
                    var(--uploader-file-hover-margin, var(--uploader-file-margin-left, var(--uploader-file-margin)))
                );

                // PADDING
                padding-top: var(
                    --uploader-file-hover-padding-top,
                    var(--uploader-file-hover-padding, var(--uploader-file-padding-top, var(--uploader-file-padding)))
                );
                padding-right: var(
                    --uploader-file-hover-padding-right,
                    var(--uploader-file-hover-padding, var(--uploader-file-padding-right, var(--uploader-file-padding)))
                );
                padding-bottom: var(
                    --uploader-file-hover-padding-bottom,
                    var(--uploader-file-hover-padding, var(--uploader-file-padding-bottom, var(--uploader-file-padding)))
                );
                padding-left: var(
                    --uploader-file-hover-padding-left,
                    var(--uploader-file-hover-padding, var(--uploader-file-padding-left, var(--uploader-file-padding)))
                );

                // FONT
                font-size: var(--uploader-file-hover-font-size, var(--uploader-file-font-size));
                letter-spacing: var(--uploader-file-hover-letter-spacing, var(--uploader-file-letter-spacing));
                font-weight: var(--uploader-file-hover-font-weight, var(--uploader-file-font-weight));
                font-style: var(--uploader-file-hover-font-style, var(--uploader-file-font-style));
                color: var(--uploader-file-hover-font-color, var(--uploader-file-font-color));
                font-family: var(--uploader-file-hover-font-family, var(--uploader-file-font-family));
                line-height: var(--uploader-file-hover-line-height, var(--uploader-file-line-height));

                // BACKGROUND
                background-color: var(--uploader-file-hover-background-color, var(--uploader-file-background-color));
            }
        }

        .drop-container {
            // BORDER
            border-top-left-radius: var(
                --uploader-dropzone-border-left-radius,
                var(--uploader-dropzone-border-top-left-radius, var(--uploader-dropzone-border-radius))
            );
            border-bottom-left-radius: var(
                --uploader-dropzone-border-left-radius,
                var(--uploader-dropzone-border-bottom-left-radius, var(--uploader-dropzone-border-radius))
            );

            border-left-color: var(--uploader-dropzone-border-left-color, var(--uploader-dropzone-border-color));
            border-left-style: var(--uploader-dropzone-border-left-style, var(--uploader-dropzone-border-style));
            border-left-width: var(--uploader-dropzone-border-left-width, var(--uploader-dropzone-border-width));

            border-top-right-radius: var(
                --uploader-dropzone-border-right-radius,
                var(--uploader-dropzone-border-top-right-radius, var(--uploader-dropzone-border-radius))
            );
            border-bottom-right-radius: var(
                --uploader-dropzone-border-right-radius,
                var(--uploader-dropzone-border-bottom-right-radius, var(--uploader-dropzone-border-radius))
            );

            border-right-color: var(--uploader-dropzone-border-right-color, var(--uploader-dropzone-border-color));
            border-right-style: var(--uploader-dropzone-border-right-style, var(--uploader-dropzone-border-style));
            border-right-width: var(--uploader-dropzone-border-right-width, var(--uploader-dropzone-border-width));

            border-top-left-radius: var(
                --uploader-dropzone-border-top-radius,
                var(--uploader-dropzone-border-top-left-radius, var(--uploader-dropzone-border-radius))
            );
            border-top-right-radius: var(
                --uploader-dropzone-border-top-radius,
                var(--uploader-dropzone-border-top-right-radius, var(--uploader-dropzone-border-radius))
            );

            border-top-color: var(--uploader-dropzone-border-top-color, var(--uploader-dropzone-border-color));
            border-top-style: var(--uploader-dropzone-border-top-style, var(--uploader-dropzone-border-style));
            border-top-width: var(--uploader-dropzone-border-top-width, var(--uploader-dropzone-border-width));

            border-bottom-left-radius: var(
                --uploader-dropzone-border-bottom-radius,
                var(--uploader-dropzone-border-bottom-left-radius, var(--uploader-dropzone-border-radius))
            );
            border-bottom-right-radius: var(
                --uploader-dropzone-border-bottom-radius,
                var(--uploader-dropzone-border-bottom-right-radius, var(--uploader-dropzone-border-radius))
            );

            border-bottom-color: var(--uploader-dropzone-border-bottom-color, var(--uploader-dropzone-border-color));
            border-bottom-style: var(--uploader-dropzone-border-bottom-style, var(--uploader-dropzone-border-style));
            border-bottom-width: var(--uploader-dropzone-border-bottom-width, var(--uploader-dropzone-border-width));

            // MARGIN
            margin-top: var(--uploader-dropzone-margin-top, var(--uploader-dropzone-margin));
            margin-right: var(--uploader-dropzone-margin-right, var(--uploader-dropzone-margin));
            margin-bottom: var(--uploader-dropzone-margin-bottom, var(--uploader-dropzone-margin));
            margin-left: var(--uploader-dropzone-margin-left, var(--uploader-dropzone-margin));

            // PADDING
            padding-top: var(--uploader-dropzone-padding-top, var(--uploader-dropzone-padding));
            padding-right: var(--uploader-dropzone-padding-right, var(--uploader-dropzone-padding));
            padding-bottom: var(--uploader-dropzone-padding-bottom, var(--uploader-dropzone-padding));
            padding-left: var(--uploader-dropzone-padding-left, var(--uploader-dropzone-padding));

            // FONT
            font-style: var(--uploader-dropzone-font-style);
            font-family: var(--uploader-dropzone-font-family);
            font-size: var(--uploader-dropzone-font-size);
            font-weight: var(--uploader-dropzone-font-weight);
            color: var(--uploader-dropzone-color);
            line-height: var(--uploader-dropzone-line-height);
            letter-spacing: var(--uploader-dropzone-letter-spacing);
            font-stretch: var(--uploader-dropzone-font-stretch);
            font-variant: var(--uploader-dropzone-font-variant);
            font-variant-caps: var(--uploader-dropzone-font-variant-caps);
            text-align: var(--uploader-dropzone-text-align);
            white-space: var(--uploader-dropzone-white-space);
            word-break: var(--uploader-dropzone-word-break);

            text-decoration-line: var(-uploader-dropzone-text-decoration-line);
            text-decoration-style: var(-uploader-dropzone-text-decoration-style);
            text-decoration-color: var(-uploader-dropzone-text-decoration-color);
            text-decoration-thickness: var(-uploader-dropzone-text-decoration-thickness);

            // BACKGROUND
            background-color: var(--uploader-dropzone-background-color);

            &:hover,
            .is-drop-over {
                // BORDER
                border-top-left-radius: var(
                    --uploader-dropzone-hover-border-left-radius,
                    var(
                        --uploader-dropzone-hover-border-radius,
                        var(--uploader-dropzone-border-left-radius, var(--uploader-dropzone-border-radius))
                    )
                );
                border-bottom-left-radius: var(
                    --uploader-dropzone-hover-border-left-radius,
                    var(
                        --uploader-dropzone-hover-border-radius,
                        var(--uploader-dropzone-border-left-radius, var(--uploader-dropzone-border-radius))
                    )
                );

                border-left-color: var(
                    --uploader-dropzone-hover-border-left-color,
                    var(
                        --uploader-dropzone-hover-border-color,
                        var(--uploader-dropzone-border-left-color, var(--uploader-dropzone-border-color))
                    )
                );
                border-left-style: var(
                    --uploader-dropzone-hover-border-left-style,
                    var(
                        --uploader-dropzone-hover-border-style,
                        var(--uploader-dropzone-border-left-style, var(--uploader-dropzone-border-style))
                    )
                );
                border-left-width: var(
                    --uploader-dropzone-hover-border-left-width,
                    var(
                        --uploader-dropzone-hover-border-width,
                        var(--uploader-dropzone-border-left-width, var(--uploader-dropzone-border-width))
                    )
                );

                border-top-right-radius: var(
                    --uploader-dropzone-hover-border-right-radius,
                    var(
                        --uploader-dropzone-hover-border-radius,
                        var(--uploader-dropzone-border-right-radius, var(--uploader-dropzone-border-radius))
                    )
                );
                border-bottom-right-radius: var(
                    --uploader-dropzone-hover-border-right-radius,
                    var(
                        --uploader-dropzone-hover-border-radius,
                        var(--uploader-dropzone-border-right-radius, var(--uploader-dropzone-border-radius))
                    )
                );

                border-right-color: var(
                    --uploader-dropzone-hover-border-right-color,
                    var(
                        --uploader-dropzone-hover-border-color,
                        var(--uploader-dropzone-border-right-color, var(--uploader-dropzone-border-color))
                    )
                );
                border-right-style: var(
                    --uploader-dropzone-hover-border-right-style,
                    var(
                        --uploader-dropzone-hover-border-style,
                        var(--uploader-dropzone-border-right-style, var(--uploader-dropzone-border-style))
                    )
                );
                border-right-width: var(
                    --uploader-dropzone-hover-border-right-width,
                    var(
                        --uploader-dropzone-hover-border-width,
                        var(--uploader-dropzone-border-right-width, var(--uploader-dropzone-border-width))
                    )
                );

                border-top-left-radius: var(
                    --uploader-dropzone-hover-border-top-radius,
                    var(
                        --uploader-dropzone-hover-border-radius,
                        var(--uploader-dropzone-border-top-radius, var(--uploader-dropzone-border-radius))
                    )
                );
                border-top-right-radius: var(
                    --uploader-dropzone-hover-border-top-radius,
                    var(
                        --uploader-dropzone-hover-border-radius,
                        var(--uploader-dropzone-border-top-radius, var(--uploader-dropzone-border-radius))
                    )
                );
                border-top-color: var(
                    --uploader-dropzone-hover-border-top-color,
                    var(
                        --uploader-dropzone-hover-border-color,
                        var(--uploader-dropzone-border-top-color, var(--uploader-dropzone-border-color))
                    )
                );
                border-top-style: var(
                    --uploader-dropzone-hover-border-top-style,
                    var(
                        --uploader-dropzone-hover-border-style,
                        var(--uploader-dropzone-border-top-style, var(--uploader-dropzone-border-style))
                    )
                );
                border-top-width: var(
                    --uploader-dropzone-hover-border-top-width,
                    var(
                        --uploader-dropzone-hover-border-width,
                        var(--uploader-dropzone-border-top-width, var(--uploader-dropzone-border-width))
                    )
                );

                border-bottom-left-radius: var(
                    --uploader-dropzone-hover-border-bottom-radius,
                    var(
                        --uploader-dropzone-hover-border-radius,
                        var(--uploader-dropzone-border-bottom-radius, var(--uploader-dropzone-border-radius))
                    )
                );
                border-bottom-right-radius: var(
                    --uploader-dropzone-hover-border-bottom-radius,
                    var(
                        --uploader-dropzone-hover-border-radius,
                        var(--uploader-dropzone-border-bottom-radius, var(--uploader-dropzone-border-radius))
                    )
                );

                border-bottom-color: var(
                    --uploader-dropzone-hover-border-bottom-color,
                    var(
                        --uploader-dropzone-hover-border-color,
                        var(--uploader-dropzone-border-bottom-color, var(--uploader-dropzone-border-color))
                    )
                );
                border-bottom-style: var(
                    --uploader-dropzone-hover-border-bottom-style,
                    var(
                        --uploader-dropzone-hover-border-style,
                        var(--uploader-dropzone-border-bottom-style, var(--uploader-dropzone-border-style))
                    )
                );
                border-bottom-width: var(
                    --uploader-dropzone-hover-border-bottom-width,
                    var(
                        --uploader-dropzone-hover-border-width,
                        var(--uploader-dropzone-border-bottom-width, var(--uploader-dropzone-border-width))
                    )
                );

                // MARGIN
                margin-top: var(
                    --uploader-dropzone-hover-margin-top,
                    var(--uploader-dropzone-hover-margin, var(--uploader-dropzone-margin-top, var(--uploader-dropzone-margin)))
                );
                margin-right: var(
                    --uploader-dropzone-hover-margin-right,
                    var(--uploader-dropzone-hover-margin, var(--uploader-dropzone-margin-right, var(--uploader-dropzone-margin)))
                );
                margin-bottom: var(
                    --uploader-dropzone-hover-margin-bottom,
                    var(--uploader-dropzone-hover-margin, var(--uploader-dropzone-margin-bottom, var(--uploader-dropzone-margin)))
                );
                margin-left: var(
                    --uploader-dropzone-hover-margin-left,
                    var(--uploader-dropzone-hover-margin, var(--uploader-dropzone-margin-left, var(--uploader-dropzone-margin)))
                );

                // PADDING
                padding-top: var(
                    --uploader-dropzone-hover-padding-top,
                    var(--uploader-dropzone-hover-padding, var(--uploader-dropzone-padding-top, var(--uploader-dropzone-padding)))
                );
                padding-right: var(
                    --uploader-dropzone-hover-padding-right,
                    var(--uploader-dropzone-hover-padding, var(--uploader-dropzone-padding-right, var(--uploader-dropzone-padding)))
                );
                padding-bottom: var(
                    --uploader-dropzone-hover-padding-bottom,
                    var(--uploader-dropzone-hover-padding, var(--uploader-dropzone-padding-bottom, var(--uploader-dropzone-padding)))
                );
                padding-left: var(
                    --uploader-dropzone-hover-padding-left,
                    var(--uploader-dropzone-hover-padding, var(--uploader-dropzone-padding-left, var(--uploader-dropzone-padding)))
                );

                // FONT
                font-style: var(--uploader-dropzone-hover-font-style, var(--uploader-dropzone-font-style));
                font-family: var(--uploader-dropzone-hover-font-family, var(--uploader-dropzone-font-family));
                font-size: var(--uploader-dropzone-hover-font-size, var(--uploader-dropzone-font-size));
                font-weight: var(--uploader-dropzone-hover-font-weight, var(--uploader-dropzone-font-weight));
                color: var(--uploader-dropzone-hover-color, var(--uploader-dropzone-color));
                line-height: var(--uploader-dropzone-hover-line-height, var(--uploader-dropzone-line-height));
                letter-spacing: var(--uploader-dropzone-hover-letter-spacing, var(--uploader-dropzone-letter-spacing));
                font-stretch: var(--uploader-dropzone-hover-font-stretch, var(--uploader-dropzone-font-stretch));
                font-variant: var(--uploader-dropzone-hover-font-variant, var(--uploader-dropzone-font-variant));
                font-variant-caps: var(--uploader-dropzone-hover-font-variant-caps, var(--uploader-dropzone-font-variant-caps));
                text-align: var(--uploader-dropzone-hover-text-align, var(--uploader-dropzone-text-align));
                white-space: var(--uploader-dropzone-hover-white-space, var(--uploader-dropzone-white-space));
                word-break: var(--uploader-dropzone-hover-word-break, var(--uploader-dropzone-word-break));

                text-decoration-line: var(-uploader-dropzone-hover-text-decoration-line, var(-uploader-dropzone-text-decoration-line));
                text-decoration-style: var(-uploader-dropzone-hover-text-decoration-style, var(-uploader-dropzone-text-decoration-style));
                text-decoration-color: var(-uploader-dropzone-hover-text-decoration-color, var(-uploader-dropzone-text-decoration-color));
                text-decoration-thickness: var(
                    -uploader-dropzone-hover-text-decoration-thickness,
                    var(-uploader-dropzone-text-decoration-thickness)
                );

                // BACKGROUND
                background-color: var(--uploader-dropzone-hover-background-color, var(--uploader-dropzone-background-color));
            }
        }

        button.mat-raised-button,
        button.mdc-button {
            --mdc-protected-button-container-color: var(--uploader-button-background-color);
            --mdc-protected-button-label-text-color: var(--uploader-button-color);
            --mdc-typography-button-font-family: var(--uploader-button-font-family);
            --mdc-typography-button-font-size: var(--uploader-button-font-size);
            --mdc-typography-button-font-weight: var(--uploader-button-font-weight);
            --mdc-typography-button-letter-spacing: var(--uploader-button-letter-spacing);
            --mdc-typography-button-line-height: var(--uploader-button-text-line-height);

            font-style: var(--uploader-button-font-style);

            border-top-left-radius: var(
                --uploader-button-border-left-radius,
                var(--uploader-button-border-top-left-radius, var(--uploader-button-border-radius))
            );
            border-bottom-left-radius: var(
                --uploader-button-border-left-radius,
                var(--uploader-button-border-bottom-left-radius, var(--uploader-button-border-radius))
            );

            border-left-color: var(--uploader-button-border-left-color, var(--uploader-button-border-color));
            border-left-style: var(--uploader-button-border-left-style, var(--uploader-button-border-style));
            border-left-width: var(--uploader-button-border-left-width, var(--uploader-button-border-width));

            border-top-right-radius: var(
                --uploader-button-border-right-radius,
                var(--uploader-button-border-top-right-radius, var(--uploader-button-border-radius))
            );
            border-bottom-right-radius: var(
                --uploader-button-border-right-radius,
                var(--uploader-button-border-bottom-right-radius, var(--uploader-button-border-radius))
            );

            border-right-color: var(--uploader-button-border-right-color, var(--uploader-button-border-color));
            border-right-style: var(--uploader-button-border-right-style, var(--uploader-button-border-style));
            border-right-width: var(--uploader-button-border-right-width, var(--uploader-button-border-width));

            border-top-left-radius: var(
                --uploader-button-border-top-radius,
                var(--uploader-button-border-top-left-radius, var(--uploader-button-border-radius))
            );
            border-top-right-radius: var(
                --uploader-button-border-top-radius,
                var(--uploader-button-border-top-right-radius, var(--uploader-button-border-radius))
            );

            border-top-color: var(--uploader-button-border-top-color, var(--uploader-button-border-color));
            border-top-style: var(--uploader-button-border-top-style, var(--uploader-button-border-style));
            border-top-width: var(--uploader-button-border-top-width, var(--uploader-button-border-width));

            border-bottom-left-radius: var(
                --uploader-button-border-bottom-radius,
                var(--uploader-button-border-bottom-left-radius, var(--uploader-button-border-radius))
            );
            border-bottom-right-radius: var(
                --uploader-button-border-bottom-radius,
                var(--uploader-button-border-bottom-right-radius, var(--uploader-button-border-radius))
            );

            border-bottom-color: var(--uploader-button-border-bottom-color, var(--uploader-button-border-color));
            border-bottom-style: var(--uploader-button-border-bottom-style, var(--uploader-button-border-style));
            border-bottom-width: var(--uploader-button-border-bottom-width, var(--uploader-button-border-width));

            margin-top: var(--uploader-button-margin-top, var(--uploader-button-margin));
            margin-right: var(--uploader-button-margin-right, var(--uploader-button-margin));
            margin-bottom: var(--uploader-button-margin-bottom, var(--uploader-button-margin));
            margin-left: var(--uploader-button-margin-left, var(--uploader-button-margin));

            padding-top: var(--uploader-button-padding-top, var(--uploader-button-padding));
            padding-right: var(--uploader-button-padding-right, var(--uploader-button-padding));
            padding-bottom: var(--uploader-button-padding-bottom, var(--uploader-button-padding));
            padding-left: var(--uploader-button-padding-left, var(--uploader-button-padding));

            &:hover {
                --mdc-protected-button-container-color: var(
                    --uploader-button-hover-background-color,
                    var(--uploader-button-background-color)
                );
                --mdc-protected-button-label-text-color: var(--uploader-button-hover-color, var(--uploader-button-color));
                --mdc-typography-button-font-family: var(--uploader-button-hover-font-family, var(--uploader-button-font-family));
                --mdc-typography-button-font-size: var(--uploader-button-hover-font-size, var(--uploader-button-font-size));
                --mdc-typography-button-font-weight: var(--uploader-button-hover-font-weight, var(--uploader-button-font-weight));
                --mdc-typography-button-letter-spacing: var(--uploader-button-hover-letter-spacing, var(--uploader-button-letter-spacing));
                --mdc-typography-button-line-height: var(--uploader-button-hover-text-line-height, var(--uploader-button-text-line-height));

                font-style: var(--uploader-button-hover-font-style, var(--uploader-button-hover-style));

                border-top-left-radius: var(--uploader-button-hover-border-left-radius, var(--uploader-button-border-left-radius));
                border-bottom-left-radius: var(--uploader-button-hover-border-left-radius, var(--uploader-button-border-left-radius));
                border-left-color: var(
                    --uploader-button-hover-border-left-color,
                    var(--uploader-button-hover-border-color, var(--uploader-button-border-left-color, var(--uploader-button-border-color)))
                );
                border-left-style: var(
                    --uploader-button-hover-border-left-style,
                    var(--uploader-button-hover-border-style, var(--uploader-button-border-left-style, var(--uploader-button-border-style)))
                );
                border-left-width: var(
                    --uploader-button-hover-border-left-width,
                    var(--uploader-button-hover-border-width, var(--uploader-button-border-left-width, var(--uploader-button-border-width)))
                );

                border-top-right-radius: var(--uploader-button-hover-border-right-radius, var(--uploader-button-border-right-radius));
                border-bottom-right-radius: var(--uploader-button-hover-border-right-radius, var(--uploader-button-border-right-radius));
                border-right-color: var(
                    --uploader-button-hover-border-right-color,
                    var(
                        --uploader-button-hover-border-color,
                        var(--uploader-button-border-right-color, var(--uploader-button-border-color))
                    )
                );
                border-right-style: var(
                    --uploader-button-hover-border-right-style,
                    var(
                        --uploader-button-hover-border-style,
                        var(--uploader-button-border-right-style, var(--uploader-button-border-style))
                    )
                );
                border-right-width: var(
                    --uploader-button-hover-border-right-width,
                    var(
                        --uploader-button-hover-border-width,
                        var(--uploader-button-border-right-width, var(--uploader-button-border-width))
                    )
                );

                border-top-left-radius: var(--uploader-button-hover-border-top-radius, var(--uploader-button-border-top-radius));
                border-top-right-radius: var(--uploader-button-hover-border-top-radius, var(--uploader-button-border-top-radius));
                border-top-color: var(
                    --uploader-button-hover-border-top-color,
                    var(--uploader-button-hover-border-color, var(--uploader-button-border-top-color, var(--uploader-button-border-color)))
                );
                border-top-style: var(
                    --uploader-button-hover-border-top-style,
                    var(--uploader-button-hover-border-style, var(--uploader-button-border-top-style, var(--uploader-button-border-style)))
                );
                border-top-width: var(
                    --uploader-button-hover-border-top-width,
                    var(--uploader-button-hover-border-width, var(--uploader-button-border-top-width, var(--uploader-button-border-width)))
                );

                border-bottom-left-radius: var(--uploader-button-hover-border-bottom-radius, var(--uploader-button-border-bottom-radius));
                border-bottom-right-radius: var(--uploader-button-hover-border-bottom-radius, var(--uploader-button-border-bottom-radius));
                border-bottom-color: var(
                    --uploader-button-hover-border-bottom-color,
                    var(
                        --uploader-button-hover-border-color,
                        var(--uploader-button-border-bottom-color, var(--uploader-button-border-color))
                    )
                );
                border-bottom-style: var(
                    --uploader-button-hover-border-bottom-style,
                    var(
                        --uploader-button-hover-border-style,
                        var(--uploader-button-border-bottom-style, var(--uploader-button-border-style))
                    )
                );
                border-bottom-width: var(
                    --uploader-button-hover-border-bottom-width,
                    var(
                        --uploader-button-hover-border-width,
                        var(--uploader-button-border-bottom-width, var(--uploader-button-border-width))
                    )
                );

                margin-top: var(
                    --uploader-button-hover-margin-top,
                    var(--uploader-button-hover-margin, var(--uploader-button-margin-top, var(--uploader-button-margin)))
                );
                margin-right: var(
                    --uploader-button-hover-margin-right,
                    var(--uploader-button-hover-margin, var(--uploader-button-margin-right, var(--uploader-button-margin)))
                );
                margin-bottom: var(
                    --uploader-button-hover-margin-bottom,
                    var(--uploader-button-hover-margin, var(--uploader-button-margin-bottom, var(--uploader-button-margin)))
                );
                margin-left: var(
                    --uploader-button-hover-margin-left,
                    var(--uploader-button-hover-margin, var(--uploader-button-margin-left, var(--uploader-button-margin)))
                );

                padding-top: var(
                    --uploader-button-hover-padding-top,
                    var(--uploader-button-hover-padding, var(--uploader-button-padding-top, var(--uploader-button-padding)))
                );
                padding-right: var(
                    --uploader-button-hover-padding-right,
                    var(--uploader-button-hover-padding, var(--uploader-button-padding-right, var(--uploader-button-padding)))
                );
                padding-bottom: var(
                    --uploader-button-hover-padding-bottom,
                    var(--uploader-button-hover-padding, var(--uploader-button-padding-bottom, var(--uploader-button-padding)))
                );
                padding-left: var(
                    --uploader-button-hover-padding-left,
                    var(--uploader-button-hover-padding, var(--uploader-button-padding-left, var(--uploader-button-padding)))
                );
            }
        }
    }

    // SLIDE TOGGLE
    .mat-mdc-slide-toggle,
    .mat-mdc-slide-toggle.mat-accent {
        --mdc-switch-selected-handle-color: var(--slide-toggle-handle-color) !important;
        --mdc-switch-selected-hover-handle-color: var(--slide-toggle-hover-handle-color, var(--slide-toggle-handle-color)) !important;
        --mdc-switch-selected-focus-handle-color: var(
            --slide-toggle-focus-within-handle-color,
            var(--slide-toggle-hover-handle-color, var(--slide-toggle-handle-color))
        ) !important;
        --mdc-switch-selected-pressed-handle-color: var(--slide-toggle-handle-color) !important;
        --mdc-switch-handle-surface-color: var(--slide-toggle-handle-color) !important;

        --mdc-switch-selected-track-color: var(--slide-toggle-track-color) !important;
        --mdc-switch-selected-hover-track-color: var(--slide-toggle-hover-track-color, var(--slide-toggle-track-color)) !important;
        --mdc-switch-selected-focus-track-color: var(
            --slide-toggle-focus-within-track-color,
            var(--slide-toggle-hover-track-color, var(--slide-toggle-track-color))
        ) !important;
        --mdc-switch-selected-pressed-track-color: var(--slide-toggle-track-color) !important;
    }

    mat-slide-toggle .mdc-form-field label {
        --mat-slide-toggle-label-text-size: var(--slide-toggle-font-size);
        --mat-slide-toggle-label-text-font: var(--slide-toggle-font-family);
        --mat-slide-toggle-label-text-tracking: var(--slide-toggle-letter-spacing);
        --mat-slide-toggle-label-text-weight: var(--slide-toggle-font-weight);
        --mat-slide-toggle-label-text-line-height: var(--slide-toggle-line-height);

        font-style: var(--slide-toggle-font-style);
        color: var(--slide-toggle-color);
        letter-spacing: var(--slide-toggle-letter-spacing);
        font-stretch: var(--slide-toggle-font-stretch);
        font-variant: var(--slide-toggle-font-variant);
        font-variant-caps: var(--slide-toggle-font-variant-caps);
        text-align: var(-part-text-align);

        margin-top: var(--slide-toggle-margin-top, var(--slide-toggle-margin));
        margin-right: var(--slide-toggle-margin-right, var(--slide-toggle-margin));
        margin-bottom: var(--slide-toggle-margin-bottom, var(--slide-toggle-margin));
        margin-left: var(--slide-toggle-margin-left, var(--slide-toggle-margin));
    }

    mat-slide-toggle:hover .mdc-form-field label {
        --mat-slide-toggle-label-text-size: var(--slide-toggle-hover-font-size, var(--slide-toggle-font-size));
        --mat-slide-toggle-label-text-font: var(--slide-toggle-hover-font-family, var(--slide-toggle-font-family));
        --mat-slide-toggle-label-text-tracking: var(--slide-toggle-hover-letter-spacing, var(--slide-toggle-letter-spacing));
        --mat-slide-toggle-label-text-weight: var(--slide-toggle-hover-font-weight, var(--slide-toggle-font-weight));
        --mat-slide-toggle-label-text-line-height: var(--slide-toggle-hover-font-line-height, var(--slide-toggle-font-line-height));

        font-style: var(--slide-toggle-hover-font-style, var(--slide-toggle-font-style));
        color: var(--slide-toggle-hover-color, var(--slide-toggle-color));
        letter-spacing: var(--slide-toggle-hover-letter-spacing, var(--slide-toggle-letter-spacing));
        font-stretch: var(--slide-toggle-hover-font-stretch, var(--slide-toggle-font-stretch));
        font-variant: var(--slide-toggle-hover-font-variant, var(--slide-toggle-font-variant));
        font-variant-caps: var(--slide-toggle-hover-font-variant-caps, var(--slide-toggle-font-variant-caps));
        text-align: var(--slide-toggle-hover-text-align, var(--slide-toggle-text-align));

        margin-top: var(
            --slide-toggle-hover-margin-top,
            var(--slide-toggle-hover-margin, var(--slide-toggle-margin-top, var(--slide-toggle-margin)))
        );
        margin-right: var(
            --slide-toggle-hover-margin-right,
            var(--slide-toggle-hover-margin, var(--slide-toggle-margin-right, var(--slide-toggle-margin)))
        );
        margin-bottom: var(
            --slide-toggle-hover-margin-bottom,
            var(--slide-toggle-hover-margin, var(--slide-toggle-margin-bottom, var(--slide-toggle-margin)))
        );
        margin-left: var(
            --slide-toggle-hover-margin-left,
            var(--slide-toggle-hover-margin, var(--slide-toggle-margin-left, var(--slide-toggle-margin)))
        );
    }

    mat-slide-toggle:focus-within .mdc-form-field label {
        --mat-slide-toggle-label-text-size: var(
            --slide-toggle-focus-within-font-size,
            var(--slide-toggle-hover-font-size, var(--slide-toggle-font-size))
        );
        --mat-slide-toggle-label-text-font: var(
            --slide-toggle-focus-within-font-family,
            var(--slide-toggle-hover-font-family, var(--slide-toggle-font-family))
        );
        --mat-slide-toggle-label-text-tracking: var(
            --slide-toggle-focus-within-letter-spacing,
            var(--slide-toggle-hover-letter-spacing, var(--slide-toggle-letter-spacing))
        );
        --mat-slide-toggle-label-text-weight: var(
            --slide-toggle-focus-within-font-weight,
            var(--slide-toggle-hover-font-weight, var(--slide-toggle-font-weight))
        );
        --mat-slide-toggle-label-text-line-height: var(
            --slide-toggle-focus-within-font-line-height,
            var(--slide-toggle-hover-font-line-height, var(--slide-toggle-font-line-height))
        );

        font-style: var(--slide-toggle-focus-within-font-style, var(--slide-toggle-hover-font-style, var(--slide-toggle-font-style)));
        color: var(--slide-toggle-focus-within-color, var(--slide-toggle-hover-color, var(--slide-toggle-color)));
        font-stretch: var(
            --slide-toggle-focus-within-font-stretch,
            var(--slide-toggle-hover-font-stretch, var(--slide-toggle-font-stretch))
        );
        font-variant: var(
            --slide-toggle-focus-within-font-variant,
            var(--slide-toggle-hover-font-variant, var(--slide-toggle-font-variant))
        );
        font-variant-caps: var(
            --slide-toggle-focus-within-font-variant-caps,
            var(--slide-toggle-hover-font-variant-caps, var(--slide-toggle-font-variant-caps))
        );
        text-align: var(--slide-toggle-focus-within-text-align, var(--slide-toggle-hover-text-align, var(--slide-toggle-text-align)));

        margin-top: var(
            --slide-toggle-focus-within-margin-top,
            var(--slide-toggle-focus-within-margin, var(--slide-toggle-margin-top, var(--slide-toggle-margin)))
        );
        margin-right: var(
            --slide-toggle-focus-within-margin-right,
            var(--slide-toggle-focus-within-margin, var(--slide-toggle-margin-right, var(--slide-toggle-margin)))
        );
        margin-bottom: var(
            --slide-toggle-focus-within-margin-bottom,
            var(--slide-toggle-focus-within-margin, var(--slide-toggle-margin-bottom, var(--slide-toggle-margin)))
        );
        margin-left: var(
            --slide-toggle-focus-within-margin-left,
            var(--slide-toggle-focus-within-margin, var(--slide-toggle-margin-left, var(--slide-toggle-margin)))
        );
    }

    .mat-mdc-slide-toggle:hover,
    .mat-mdc-slide-toggle.mat-accent {
        --mdc-switch-handle-surface-color: var(--slide-toggle-hover-handle-color, var(--slide-toggle-selected-handle-color)) !important;
    }

    .mat-mdc-slide-toggle:focus-within,
    .mat-mdc-slide-toggle.mat-accent {
        --mdc-switch-handle-surface-color: var(
            --slide-toggle-focus-within-handle-color,
            var(--slide-toggle-hover-handle-color, var(--slide-toggle-selected-handle-color))
        ) !important;
    }

    // BUTTON
    .mdc-button,
    .mat-mdc-raised-button:not(:disabled) {
        --mdc-protected-button-container-color: var(--button-background-color);
        --mdc-protected-button-label-text-color: var(--button-label-color);
        --mdc-typography-button-font-family: var(--button-label-font-family);
        --mdc-typography-button-font-size: var(--button-label-font-size);
        --mdc-typography-button-font-weight: var(--button-label-font-weight);
        --mdc-typography-button-letter-spacing: var(--button-label-font-letter-spacing);
        --mdc-typography-button-line-height: var(--button-label-text-line-height);

        --mdc-protected-button-label-text-font: var(--button-label-font-family);
        --mdc-protected-button-label-text-size: var(--button-label-font-size);
        --mdc-protected-button-label-text-tracking: var(--button-label-font-letter-spacing);
        --mdc-protected-button-label-text-weight: var(--button-label-font-weight);

        background-image: var(--button-background-image);

        font-style: var(--button-label-font-style, var(--label-medium-font-style));

        font-stretch: var(--button-label-font-stretch);
        font-variant: var(--button-label-font-variant);
        text-align: var(--button-label-text-align-var);

        border-top-left-radius: var(--button-border-left-radius, var(--button-border-radius));
        border-bottom-left-radius: var(--button-border-left-radius, var(--button-border-radius));
        border-left-color: var(--button-border-left-color, var(--button-border-color));
        border-left-style: var(--button-border-left-style, var(--button-border-style));
        border-left-width: var(--button-border-left-width, var(--button-border-width));

        border-top-right-radius: var(--button-border-right-radius, var(--button-border-radius));
        border-bottom-right-radius: var(--button-border-right-radius, var(--button-border-radius));
        border-right-color: var(--button-border-right-color, var(--button-border-color));
        border-right-style: var(--button-border-right-style, var(--button-border-style));
        border-right-width: var(--button-border-right-width, var(--button-border-width));

        border-top-left-radius: var(--button-border-top-radius, var(--button-border-radius));
        border-top-right-radius: var(--button-border-top-radius, var(--button-border-radius));
        border-top-color: var(--button-border-top-color, var(--button-border-color));
        border-top-style: var(--button-border-top-style, var(--button-border-style));
        border-top-width: var(--button-border-top-width, var(--button-border-width));

        border-bottom-left-radius: var(--button-border-bottom-radius, var(--button-border-radius));
        border-bottom-right-radius: var(--button-border-bottom-radius, var(--button-border-radius));
        border-bottom-color: var(--button-border-bottom-color, var(--button-border-color));
        border-bottom-style: var(--button-border-bottom-style, var(--button-border-style));
        border-bottom-width: var(--button-border-bottom-width, var(--button-border-width));

        span.mdc-button__label {
            padding-top: var(--button-padding-top, var(--button-padding));
            padding-right: var(--button-padding-right, var(--button-padding));
            padding-bottom: var(--button-padding-bottom, var(--button-padding));
            padding-left: var(--button-padding-left, var(--button-padding));
        }

        &:hover {
            --mdc-protected-button-container-color: var(--button-hover-background-color, var(--button-background-color));
            --mdc-protected-button-label-text-color: var(--button-label-hover-color, var(--button-label-color));
            --mdc-typography-button-font-family: var(--button-label-hover-font-family, var(--button-label-font-family));
            --mdc-typography-button-font-size: var(--button-label-hover-font-size, var(--button-label-font-size));
            --mdc-typography-button-font-weight: var(--button-label-hover-font-weight, var(--button-label-font-weight));
            --mdc-typography-button-letter-spacing: var(--button-label-hover-font-letter-spacing, var(--button-label-font-letter-spacing));
            --mdc-typography-button-line-height: var(--button-label-hover-text-line-height, var(--button-label-text-line-height));

            --mdc-protected-button-label-text-font: var(--button-label-hover-font-family, var(--button-label-font-family));
            --mdc-protected-button-label-text-size: var(--button-label-hover-font-size, var(--button-label-font-size));
            --mdc-protected-button-label-text-tracking: var(
                --button-label-hover-font-letter-spacing,
                var(--button-label-font-letter-spacing)
            );
            --mdc-protected-button-label-text-weight: var(--button-label-hover-font-weight, var(--button-label-font-weight));

            background-image: var(--button-hover-background-image, var(--button-background-image));
            font-style: var(--button-label-hover-font-style, var(--button-label-font-style));

            font-stretch: var(--button-label-hover-font-stretch, var(--button-label-font-stretch));
            font-variant: var(--button-label-hover-font-variant, var(--button-label-font-variant));
            text-align: var(--button-label-hover-text-align-var, var(--button-label-text-align-var));

            border-top-left-radius: var(
                --button-hover-border-left-radius,
                var(--button-hover-border-radius, var(--button-border-left-radius, var(--button-border-radius)))
            );
            border-bottom-left-radius: var(
                --button-hover-border-left-radius,
                var(--button-hover-border-radius, var(--button-border-left-radius, var(--button-border-radius)))
            );
            border-left-color: var(
                --button-hover-border-left-color,
                var(--button-hover-border-color, var(--button-border-left-color, var(--button-border-color)))
            );
            border-left-style: var(
                --button-hover-border-left-style,
                var(--button-hover-border-style, var(--button-border-left-style, var(--button-border-style)))
            );
            border-left-width: var(
                --button-hover-border-left-width,
                var(--button-hover-border-width, var(--button-border-left-width, var(--button-border-width)))
            );

            border-top-right-radius: var(
                --button-hover-border-right-radius,
                var(--button-hover-border-radius, var(--button-border-right-radius, var(--button-border-radius)))
            );
            border-bottom-right-radius: var(
                --button-hover-border-right-radius,
                var(--button-hover-border-radius, var(--button-border-right-radius, var(--button-border-radius)))
            );
            border-right-color: var(
                --button-hover-border-right-color,
                var(--button-hover-border-color, var(--button-border-right-color, var(--button-border-color)))
            );
            border-right-style: var(
                --button-hover-border-right-style,
                var(--button-hover-border-style, var(--button-border-right-style, var(--button-border-style)))
            );
            border-right-width: var(
                --button-hover-border-right-width,
                var(--button-hover-border-width, var(--button-border-right-width, var(--button-border-width)))
            );

            border-top-left-radius: var(
                --button-hover-border-top-radius,
                var(--button-hover-border-radius, var(--button-border-top-radius, var(--button-border-radius)))
            );
            border-top-right-radius: var(
                --button-hover-border-top-radius,
                var(--button-hover-border-radius, var(--button-border-top-radius, var(--button-border-radius)))
            );
            border-top-color: var(
                --button-hover-border-top-color,
                var(--button-hover-border-color, var(--button-border-top-color, var(--button-border-color)))
            );
            border-top-style: var(
                --button-hover-border-top-style,
                var(--button-hover-border-style, var(--button-border-top-style, var(--button-border-style)))
            );
            border-top-width: var(
                --button-hover-border-top-width,
                var(--button-hover-border-width, var(--button-border-top-width, var(--button-border-width)))
            );

            border-bottom-left-radius: var(
                --button-hover-border-bottom-radius,
                var(--button-hover-border-radius, var(--button-border-bottom-radius, var(--button-border-radius)))
            );
            border-bottom-right-radius: var(
                --button-hover-border-bottom-radius,
                var(--button-hover-border-radius, var(--button-border-bottom-radius, var(--button-border-radius)))
            );
            border-bottom-color: var(
                --button-hover-border-bottom-color,
                var(--button-hover-border-color, var(--button-border-bottom-color, var(--button-border-color)))
            );
            border-bottom-style: var(
                --button-hover-border-bottom-style,
                var(--button-hover-border-style, var(--button-border-bottom-style, var(--button-border-style)))
            );
            border-bottom-width: var(
                --button-hover-border-bottom-width,
                var(--button-hover-border-width, var(--button-border-bottom-width, var(--button-border-width)))
            );

            span.mdc-button__label {
                padding-top: var(--button-hover-padding-top, var(--button-hover-padding, var(--button-padding-top, var(--button-padding))));
                padding-right: var(
                    --button-hover-padding-right,
                    var(--button-hover-padding, var(--button-padding-right, var(--button-padding)))
                );
                padding-bottom: var(
                    --button-hover-padding-bottom,
                    var(--button-hover-padding, var(--button-padding-bottom, var(--button-padding)))
                );
                padding-left: var(
                    --button-hover-padding-left,
                    var(--button-hover-padding, var(--button-padding-left, var(--button-padding)))
                );
            }
        }
    }

    // CHIP
    --chip-label-text-font: 'Inter';
    --chip-label-text-size: 12px;
    --chip-label-text-weight: normal;
    --chip-label-text-color: black;
    .mat-mdc-chip-set .mdc-evolution-chip.part-content {
        margin-top: var(--part-margin-top, var(--part-margin));
        margin-right: var(--part-margin-right, var(--part-margin));
        margin-bottom: var(--part-margin-bottom, var(--part-margin));
        margin-left: var(--part-margin-left, var(--part-margin));
    }
    .mat-mdc-standard-chip.part-content {
        --mdc-chip-label-text-font: var(--input-answer-font-family);
        --mdc-chip-label-text-size: var(--input-answer-font-size);
        --mdc-chip-label-text-weight: var(--input-answer-font-weight);
        --mdc-chip-disabled-label-text-color: var(--input-answer-color);
        --mdc-chip-label-text-color: var(--input-answer-color);
        --mdc-chip-label-text-line-height: var(--input-answer-line-height);
        --mdc-chip-label-text-tracking: var(--input-answer-letter-spacing);

        font-stretch: var(--input-answer-font-stretch);
        font-style: var(--input-answer-font-style);
        font-variant: var(--input-answer-font-variant);
        font-variant-caps: var(--input-answer-font-variant-caps);
        text-align: var(--input-answer-text-align-var);

        border-top-left-radius: var(--part-border-left-radius, var(--part-border-top-left-radius, var(--part-border-radius)));
        border-bottom-left-radius: var(--part-border-left-radius, var(--part-border-bottom-left-radius, var(--part-border-radius)));
        border-top-right-radius: var(--part-border-right-radius, var(--part-border-top-right-radius, var(--part-border-radius)));
        border-bottom-right-radius: var(--part-border-right-radius, var(--part-border-bottom-right-radius, var(--part-border-radius)));
        border-top-left-radius: var(--part-border-top-radius, var(--part-border-top-left-radius, var(--part-border-radius)));
        border-top-right-radius: var(--part-border-top-radius, var(--part-border-top-right-radius, var(--part-border-radius)));
        border-bottom-left-radius: var(--part-border-bottom-radius, var(--part-border-bottom-left-radius, var(--part-border-radius)));
        border-bottom-right-radius: var(--part-border-bottom-radius, var(--part-border-bottom-right-radius, var(--part-border-radius)));

        height: 100%;

        &:hover {
            --mdc-chip-label-text-font: var(--input-answer-hover-font-family, var(--input-answer-font-family));
            --mdc-chip-label-text-size: var(--input-answer-hover-font-size, var(--input-answer-font-size));
            --mdc-chip-label-text-weight: var(--input-answer-hover-font-weight, var(--input-answer-font-weight));
            --mdc-chip-disabled-label-text-color: var(--input-answer-hover-color, var(--input-answer-color));
            --mdc-chip-label-text-color: var(--input-answer-hover-color, var(--input-answer-color));
            --mdc-chip-label-text-line-height: var(--input-answer-hover-line-height, var(--input-answer-line-height));
            --mdc-chip-label-text-tracking: var(--input-answer-hover-letter-spacing, var(--input-answer-letter-spacing));

            font-stretch: var(--input-answer-hover-font-stretch, var(--input-answer-font-stretch));
            font-style: var(--input-answer-hover-font-style, var(--input-answer-font-style));
            font-variant: var(--input-answer-hover-font-variant, var(--input-answer-font-variant));
            font-variant-caps: var(--input-answer-hover-font-variant-caps, var(--input-answer-font-variant-caps));
            text-align: var(--input-answer-hover-text-align-var, var(--input-answer-text-align-var));

            border-top-left-radius: var(
                --part-hover-border-left-radius,
                var(--part-hover-border-radius, var(--part-border-left-radius, var(--part-border-radius)))
            );
            border-bottom-left-radius: var(
                --part-hover-border-left-radius,
                var(--part-hover-border-radius, var(--part-border-left-radius, var(--part-border-radius)))
            );
            border-top-right-radius: var(
                --part-hover-border-right-radius,
                var(--part-hover-border-radius, var(--part-border-right-radius, var(--part-border-radius)))
            );
            border-bottom-right-radius: var(
                --part-hover-border-right-radius,
                var(--part-hover-border-radius, var(--part-border-right-radius, var(--part-border-radius)))
            );
            border-top-left-radius: var(
                --part-hover-border-top-radius,
                var(--part-hover-border-radius, var(--part-border-top-radius, var(--part-border-radius)))
            );
            border-top-right-radius: var(
                --part-hover-border-top-radius,
                var(--part-hover-border-radius, var(--part-border-top-radius, var(--part-border-radius)))
            );
            border-bottom-left-radius: var(
                --part-hover-border-bottom-radius,
                var(--part-hover-border-radius, var(--part-border-bottom-radius, var(--part-border-radius)))
            );
            border-bottom-right-radius: var(
                --part-hover-border-bottom-radius,
                var(--part-hover-border-radius, var(--part-border-bottom-radius, var(--part-border-radius)))
            );

            height: 100%;
        }

        &:focus-within {
            --mdc-chip-label-text-font: var(
                --input-answer-focus-within-font-family,
                var(--input-answer-hover-font-family, var(--input-answer-font-family))
            );
            --mdc-chip-label-text-size: var(
                --input-answer-focus-within-font-size,
                var(--input-answer-hover-font-size, var(--input-answer-font-size))
            );
            --mdc-chip-label-text-weight: var(
                --input-answer-focus-within-font-weight,
                var(--input-answer-hover-font-weight, var(--input-answer-font-weight))
            );
            --mdc-chip-disabled-label-text-color: var(
                --input-answer-focus-within-color,
                var(--input-answer-hover-color, var(--input-answer-color))
            );
            --mdc-chip-label-text-color: var(--input-answer-focus-within-color, var(--input-answer-hover-color, var(--input-answer-color)));
            --mdc-chip-label-text-line-height: var(
                --input-answer-focus-within-line-height,
                var(--input-answer-hover-line-height, var(--input-answer-line-height))
            );
            --mdc-chip-label-text-tracking: var(
                --input-answer-focus-within-letter-spacing,
                var(--input-answer-hover-letter-spacing, var(--input-answer-letter-spacing))
            );

            font-stretch: var(
                --input-answer-focus-within-font-stretch,
                var(--input-answer-hover-font-stretch, var(--input-answer-font-stretch))
            );
            font-style: var(--input-answer-focus-within-font-style, var(--input-answer-hover-font-style, var(--input-answer-font-style)));
            font-variant: var(
                --input-answer-focus-within-font-variant,
                var(--input-answer-hover-font-variant, var(--input-answer-font-variant))
            );
            font-variant-caps: var(
                --input-answer-focus-within-font-variant-caps,
                var(--input-answer-hover-font-variant-caps, var(--input-answer-font-variant-caps))
            );
            text-align: var(
                --input-answer-focus-within-text-align-var,
                var(--input-answer-hover-text-align-var, var(--input-answer-text-align-var))
            );

            border-top-left-radius: var(
                --part-focus-border-left-radius,
                var(--part-focus-border-radius, var(--part-border-left-radius, var(--part-border-radius)))
            );
            border-bottom-left-radius: var(
                --part-focus-border-left-radius,
                var(--part-focus-border-radius, var(--part-border-left-radius, var(--part-border-radius)))
            );
            border-top-right-radius: var(
                --part-focus-border-right-radius,
                var(--part-focus-border-radius, var(--part-border-right-radius, var(--part-border-radius)))
            );
            border-bottom-right-radius: var(
                --part-focus-border-right-radius,
                var(--part-focus-border-radius, var(--part-border-right-radius, var(--part-border-radius)))
            );
            border-top-left-radius: var(
                --part-focus-border-top-radius,
                var(--part-focus-border-radius, var(--part-border-top-radius, var(--part-border-radius)))
            );
            border-top-right-radius: var(
                --part-focus-border-top-radius,
                var(--part-focus-border-radius, var(--part-border-top-radius, var(--part-border-radius)))
            );
            border-bottom-left-radius: var(
                --part-focus-border-bottom-radius,
                var(--part-focus-border-radius, var(--part-border-bottom-radius, var(--part-border-radius)))
            );
            border-bottom-right-radius: var(
                --part-focus-border-bottom-radius,
                var(--part-focus-border-radius, var(--part-border-bottom-radius, var(--part-border-radius)))
            );

            height: 100%;
        }
    }

    // ICON BUTTON

    .icon-button {
        background-color: var(--icon-button-background-color);
        background-image: var(--icon-button-background-image);
        background-attachment: var(--icon-button-background-attachment);
        background-position: var(--icon-button-background-position);
        background-repeat: var(--icon-button-background-repeat);
        background-size: var(--icon-button-background-size);
        background-origin: var(--icon-button-background-origin);

        border-left-color: var(--icon-button-border-left-color, var(--icon-button-border-color));
        border-left-style: var(--icon-button-border-left-style, var(--icon-button-border-style));
        border-left-width: var(--icon-button-border-left-width, var(--icon-button-border-width));

        border-right-color: var(--icon-button-border-right-color, var(--icon-button-border-color));
        border-right-style: var(--icon-button-border-right-style, var(--icon-button-border-style));
        border-right-width: var(--icon-button-border-right-width, var(--icon-button-border-width));

        border-top-color: var(--icon-button-border-top-color, var(--icon-button-border-color));
        border-top-style: var(--icon-button-border-top-style, var(--icon-button-border-style));
        border-top-width: var(--icon-button-border-top-width, var(--icon-button-border-width));

        border-bottom-color: var(--icon-button-border-bottom-color, var(--icon-button-border-color));
        border-bottom-style: var(--icon-button-border-bottom-style, var(--icon-button-border-style));
        border-bottom-width: var(--icon-button-border-bottom-width, var(--icon-button-border-width));

        mat-icon {
            color: var(--icon-button-color);
            font-size: var(--icon-button-size);
        }

        &:hover {
            background-color: var(--icon-button-hover-background-color, var(--icon-button-background-color));
            background-image: var(--icon-button-hover-background-image, var(--icon-button-background-image));
            background-attachment: var(--icon-button-hover-background-attachment, var(--icon-button-background-attachment));
            background-position: var(--icon-button-hover-background-position, var(--icon-button-background-position));
            background-repeat: var(--icon-button-hover-background-repeat, var(--icon-button-background-repeat));
            background-size: var(--icon-button-hover-background-size, var(--icon-button-background-size));
            background-origin: var(--icon-button-hover-background-origin, var(--icon-button-background-origin));

            border-left-color: var(
                --icon-button-hover-border-left-color,
                var(--icon-button-hover-border-color, var(--icon-button-border-left-color, var(--icon-button-border-color)))
            );
            border-left-style: var(
                --icon-button-hover-border-left-style,
                var(--icon-button-hover-border-style, var(--icon-button-border-left-style, var(--icon-button-border-style)))
            );
            border-left-width: var(
                --icon-button-hover-border-left-width,
                var(--icon-button-hover-border-width, var(--icon-button-border-left-width, var(--icon-button-border-width)))
            );

            border-right-color: var(
                --icon-button-hover-border-right-color,
                var(--icon-button-hover-border-color, var(--icon-button-border-right-color, var(--icon-button-border-color)))
            );
            border-right-style: var(
                --icon-button-hover-border-right-style,
                var(--icon-button-hover-border-style, var(--icon-button-border-right-style, var(--icon-button-border-style)))
            );
            border-right-width: var(
                --icon-button-hover-border-right-width,
                var(--icon-button-hover-border-width, var(--icon-button-border-right-width, var(--icon-button-border-width)))
            );

            border-top-color: var(
                --icon-button-hover-border-top-color,
                var(--icon-button-hover-border-color, var(--icon-button-border-top-color, var(--icon-button-border-color)))
            );
            border-top-style: var(
                --icon-button-hover-border-top-style,
                var(--icon-button-hover-border-style, var(--icon-button-border-top-style, var(--icon-button-border-style)))
            );
            border-top-width: var(
                --icon-button-hover-border-top-width,
                var(--icon-button-hover-border-width, var(--icon-button-border-top-width, var(--icon-button-border-width)))
            );

            border-bottom-color: var(
                --icon-button-hover-border-bottom-color,
                var(--icon-button-hover-border-color, var(--icon-button-border-bottom-color, var(--icon-button-border-color)))
            );
            border-bottom-style: var(
                --icon-button-hover-border-bottom-style,
                var(--icon-button-hover-border-style, var(--icon-button-border-bottom-style, var(--icon-button-border-style)))
            );
            border-bottom-width: var(
                --icon-button-hover-border-bottom-width,
                var(--icon-button-hover-border-width, var(--icon-button-border-bottom-width, var(--icon-button-border-width)))
            );

            mat-icon {
                color: var(--icon-button-hover-color, var(--icon-button-color));
                font-size: var(--icon-button-hover-size, var(--icon-button-size));
            }
        }
    }

    // INPUT FIELD

    // TODO: deze mogen weg ?
    --input-border-width: 1px;
    --input-border-radius: 4px;
    --input-border-color: black;
    --input-border-style: solid;
    //--input-hover-border-style: solid;
    //--input-focus-border-style: solid;
    //--input-hover-border-width: 1px;
    //--input-hover-border-radius: 4px;

    .mat-mdc-text-field-wrapper {
        background-color: var(--input-background-color);
        border-radius: var(--input-border-radius);

        &:hover {
            background-color: var(--input-hover-background-color, var(--input-background-color));
            border-radius: var(--input-hover-border-radius, var(--input-border-radius));
        }

        &:focus {
            background-color: var(--input-focus-background-color, var(--input-background-color));
            border-radius: var(--input-focus-border-radius, var(--input-border-radius));
        }
    }

    mat-form-field input:focus {
        border: unset;
    }

    mat-form-field input[type='text']:focus,
    mat-form-field input[type='email']:focus,
    mat-form-field input[type='url']:focus,
    mat-form-field input[type='password']:focus,
    mat-form-field input[type='number']:focus,
    mat-form-field input[type='date']:focus,
    mat-form-field input[type='datetime-local']:focus,
    mat-form-field input[type='month']:focus,
    mat-form-field input[type='search']:focus,
    mat-form-field input[type='tel']:focus,
    mat-form-field input[type='time']:focus,
    mat-form-field input[type='week']:focus,
    mat-form-field input[multiple]:focus,
    mat-form-field textarea:focus,
    mat-form-field select:focus {
        border: unset;
        box-shadow: unset;
    }

    mat-form-field input[type='text'],
    mat-form-field input[type='email'],
    mat-form-field input[type='url'],
    mat-form-field input[type='password'],
    mat-form-field input[type='number'],
    mat-form-field input[type='date'],
    mat-form-field input[type='datetime-local'],
    mat-form-field input[type='month'],
    mat-form-field input[type='search'],
    mat-form-field input[type='tel'],
    mat-form-field input[type='time'],
    mat-form-field input[type='week'],
    mat-form-field input[multiple],
    mat-form-field textarea,
    mat-form-field select {
        padding-top: unset;
        padding-left: unset;
        padding-right: unset;
        padding-bottom: unset;
        border: unset;
        box-shadow: unset;
    }

    mat-form-field.mat-mdc-form-field:hover,
    mat-form-field:hover .mdc-text-field--outlined {
        --mdc-outlined-text-field-outline-width: var(--input-hover-border-width, var(--input-border-width));
        --mdc-outlined-text-field-container-shape: var(--input-hover-border-radius, var(--input-border-radius));
    }

    mat-form-field.mat-mdc-form-field,
    mat-form-field .mdc-text-field--outlined {
        --mdc-outlined-text-field-outline-width: var(--input-border-width);
        --mdc-outlined-text-field-outline-color: var(--input-border-color);
        --mdc-outlined-text-field-container-shape: var(--input-border-radius);

        --mdc-outlined-text-field-focus-outline-width: var(
            --input-focus-within-border-width,
            var(--input-focus-border-width, var(--input-hover-border-width, var(--input-border-width)))
        );
        --mdc-outlined-text-field-focus-outline-color: var(
            --input-focus-within-border-color,
            var(--input-focus-border-color, var(--input-hover-border-color, var(--input-border-color)))
        );
        --mdc-outlined-text-field-focus-container-shape: var(
            --input-focus-within-border-radius,
            var(--input-focus-border-radius, var(--input-hover-border-radius, var(--input-border-radius)))
        );
        --mdc-outlined-text-field-hover-outline-color: var(--input-hover-border-color, var(--input-border-color));

        --mat-form-field-outlined-label-text-populated-size: var(--input-label-font-size);
        --mdc-outlined-text-field-label-text-font: var(--input-label-font-family);
        --mdc-outlined-text-field-label-text-size: var(--input-label-font-size);
        --mdc-outlined-text-field-label-text-tracking: var(--input-label-font-letter-spacing);
        --mdc-outlined-text-field-label-text-weight: var(--input-label-font-weight);
        --mdc-outlined-text-field-label-text-color: var(--input-label-color);

        --mdc-outlined-text-field-focus-label-text-color: var(
            --input-label-focus-within-color,
            var(--input-label-focus-color, var(--input-label-hover-color, var(--input-label-focus-color)))
        );

        font-style: var(--input-label-font-style) !important;
    }

    mat-form-field.mat-mdc-form-field:hover,
    mat-form-field:hover .mdc-text-field--outlined {
        --mat-form-field-outlined-label-text-populated-size: var(--input-label-hover-font-size, var(--input-label-font-size));
        --mdc-outlined-text-field-label-text-font: var(--input-label-hover-font-family, var(--input-label-font-family));
        --mdc-outlined-text-field-label-text-size: var(--input-label-hover-font-size, var(--input-label-font-size));
        --mdc-outlined-text-field-label-text-tracking: var(--input-label-hover-font-letter-spacing, var(--input-label-font-letter-spacing));
        --mdc-outlined-text-field-label-text-weight: var(--input-label-hover-font-weight, var(--input-label-font-weight));
        --mdc-outlined-text-field-label-text-color: var(--input-label-hover-color, var(--input-label-color));
    }

    mat-form-field.mat-mdc-form-field.mat-focused,
    mat-form-field.mat-focused .mdc-text-field--outlined {
        --mat-form-field-outlined-label-text-populated-size: var(
            --input-label-focus-within-font-size,
            var(--input-label-focus-font-size, var(--input-label-hover-font-size, var(--input-label-font-size)))
        );
        --mdc-outlined-text-field-label-text-font: var(
            --input-label-focus-within-font-family,
            var(--input-label-focus-font-family, var(--input-label-hover-font-family, var(--input-label-font-family)))
        );
        --mdc-outlined-text-field-label-text-size: var(
            --input-label-focus-within-font-size,
            var(--input-label-focus-font-size, var(--input-label-hover-font-size, var(--input-label-font-size)))
        );
        --mdc-outlined-text-field-label-text-tracking: var(
            --input-label-focus-within-font-letter-spacing,
            var(
                --input-label-focus-font-letter-spacing,
                var(--input-label-hover-font-letter-spacing, var(--input-label-font-letter-spacing))
            )
        );
        --mdc-outlined-text-field-label-text-weight: var(
            --input-label-focus-within-font-weight,
            var(--input-label-focus-font-weight, var(--input-label-hover-font-weight, var(--input-label-font-weight)))
        );
        --mdc-outlined-text-field-label-text-color: var(
            --input-label-focus-within-color,
            var(--input-label-focus-color, var(--input-label-hover-color, var(--input-label-color)))
        );
    }

    mat-form-field.mat-mdc-form-field input,
    mat-form-field.mat-mdc-form-field textarea,
    mat-form-field.mat-mdc-form-field mat-select,
    mat-form-field .mdc-text-field--outlined input,
    mat-form-field .mdc-text-field--outlined textarea,
    mat-form-field .mdc-text-field--outlined mat-select {
        font-family: var(--input-answer-font-family) !important;
        line-height: var(--input-answer-font-line-height) !important;
        font-size: var(--input-answer-font-size) !important;
        letter-spacing: var(--input-answer-font-letter-spacing) !important;
        font-weight: var(--input-answer-font-weight) !important;
        font-stretch: var(--input-answer-font-stretch) !important;
        font-style: var(--input-answer-font-style) !important;
        font-variant: var(--input-answer-font-variant) !important;
        font-variant-caps: var(--input-answer-font-variant-caps) !important;
        text-align: var(--input-answer-font-alignment) !important;
        color: var(--input-answer-color) !important;
    }

    mat-form-field:hover .mat-mdc-form-field input,
    mat-form-field:hover .mat-mdc-form-field textarea,
    mat-form-field:hover .mat-mdc-form-field mat-select,
    mat-form-field:hover .mdc-text-field--outlined input,
    mat-form-field:hover .mdc-text-field--outlined textarea,
    mat-form-field:hover .mdc-text-field--outlined mat-select {
        font-family: var(--input-answer-hover-font-family, var(--input-answer-font-family)) !important;
        line-height: var(--input-answer-hover-font-line-height, var(--input-answer-font-line-height)) !important;
        font-size: var(--input-answer-hover-font-size, var(--input-answer-font-size)) !important;
        letter-spacing: var(--input-answer-hover-font-letter-spacing, var(--input-answer-font-letter-spacing)) !important;
        font-weight: var(--input-answer-hover-font-weight, var(--input-answer-font-weight)) !important;
        font-stretch: var(--input-answer-hover-font-stretch, var(--input-answer-font-stretch)) !important;
        font-style: var(--input-answer-hover-font-style, var(--input-answer-font-style)) !important;
        font-variant: var(--input-answer-hover-font-variant, var(--input-answer-font-variant)) !important;
        font-variant-caps: var(--input-answer-hover-font-variant-caps, var(--input-answer-font-variant-caps)) !important;
        text-align: var(--input-answer-hover-font-alignment, var(--input-answer-font-alignment)) !important;
        color: var(--input-answer-hover-color, var(--input-answer-color)) !important;
    }

    mat-form-field.mat-focused.mat-mdc-form-field input,
    mat-form-field.mat-focused.mat-mdc-form-field textarea,
    mat-form-field.mat-focused.mat-mdc-form-field mat-select,
    mat-form-field.mat-focused .mdc-text-field--outlined input,
    mat-form-field.mat-focused .mdc-text-field--outlined textarea,
    mat-form-field.mat-focused .mdc-text-field--outlined mat-select {
        font-family: var(
            --input-answer-focus-within-font-family,
            var(--input-answer-focus-font-family, var(--input-answer-hover-font-family, var(--input-answer-font-family)))
        ) !important;
        line-height: var(
            --input-answer-focus-within-font-line-height,
            var(--input-answer-focus-font-line-height, var(--input-answer-hover-font-line-height, var(--input-answer-font-line-height)))
        ) !important;
        font-size: var(
            --input-answer-focus-within-font-size,
            var(--input-answer-focus-font-size, var(--input-answer-hover-font-size, var(--input-answer-font-size)))
        ) !important;
        letter-spacing: var(
            --input-answer-focus-within-font-letter-spacing,
            var(
                --input-answer-focus-font-letter-spacing,
                var(--input-answer-hover-font-letter-spacing, var(--input-answer-font-letter-spacing))
            )
        ) !important;
        font-weight: var(
            --input-answer-focus-within-font-weight,
            var(--input-answer-focus-font-weight, var(--input-answer-hover-font-weight, var(--input-answer-font-weight)))
        ) !important;
        font-stretch: var(
            --input-answer-focus-within-font-stretch,
            var(--input-answer-focus-font-stretch, var(--input-answer-hover-font-stretch, var(--input-answer-font-stretch)))
        ) !important;
        font-style: var(
            --input-answer-focus-within-font-style,
            var(--input-answer-focus-font-style, var(--input-answer-hover-font-style, var(--input-answer-font-style)))
        ) !important;
        font-variant: var(
            --input-answer-focus-within-font-variant,
            var(--input-answer-focus-font-variant, var(--input-answer-hover-font-variant, var(--input-answer-font-variant)))
        ) !important;
        font-variant-caps: var(
            --input-answer-within-focus-font-variant-caps,
            var(--input-answer-focus-font-variant-caps, var(--input-answer-hover-font-variant-caps, var(--input-answer-font-variant-caps)))
        ) !important;
        text-align: var(
            --input-answer-focus-within-font-alignment,
            var(--input-answer-focus-font-alignment, var(--input-answer-hover-font-alignment, var(--input-answer-font-alignment)))
        ) !important;
        color: var(
            --input-answer-focus-within-color,
            var(--input-answer-focus-color, var(--input-answer-hover-color, var(--input-answer-color)))
        ) !important;
    }

    mat-form-field.mat-form-field-appearance-outline .mdc-notched-outline .mdc-notched-outline__leading,
    mat-form-field.mat-form-field-appearance-outline .mdc-notched-outline .mdc-notched-outline__notch,
    mat-form-field.mat-form-field-appearance-outline .mdc-notched-outline .mdc-notched-outline__trailing {
        border-style: var(--input-border-style);
    }

    mat-form-field.mat-form-field-appearance-outline.mat-focused .mdc-notched-outline .mdc-notched-outline__leading,
    mat-form-field.mat-form-field-appearance-outline.mat-focused .mdc-notched-outline .mdc-notched-outline__notch,
    mat-form-field.mat-form-field-appearance-outline.mat-focused .mdc-notched-outline .mdc-notched-outline__trailing {
        border-style: var(
            --input-focus-within-border-style,
            var(--input-focus-border-style, var(--input-hover-border-style, var(--input-border-style)))
        );
    }

    mat-form-field.mat-form-field-appearance-outline:hover .mdc-notched-outline .mdc-notched-outline__leading,
    mat-form-field.mat-form-field-appearance-outline:hover .mdc-notched-outline .mdc-notched-outline__notch,
    mat-form-field.mat-form-field-appearance-outline:hover .mdc-notched-outline .mdc-notched-outline__trailing {
        border-style: var(--input-hover-border-style, var(--input-border-style));
    }

    mat-form-field.mat-form-field-appearance-outline .mdc-notched-outline--notched .mdc-notched-outline__notch {
        border-top: none !important;
    }

    mat-form-field.mat-form-field-appearance-outline .mdc-notched-outline .mdc-notched-outline__leading,
    mat-form-field.mat-form-field-appearance-outline .mdc-notched-outline .mdc-notched-outline__notch {
        border-right: none !important;
    }

    mat-form-field.mat-form-field-appearance-outline .mdc-notched-outline .mdc-notched-outline__trailing {
        border-left: none !important;
    }

    .mat-form-field-underline {
        background-color: red;
    }

    .mat-form-field-ripple {
        background-color: red;
    }

    // Check box
    .checkbox-field {
        .question {
            // MARGIN
            margin-top: var(--checkbox-field-label-margin-top, var(--checkbox-field-label-margin));
            margin-right: var(--checkbox-field-label-margin-right, var(--checkbox-field-label-margin));
            margin-bottom: var(--checkbox-field-label-margin-bottom, var(--checkbox-field-label-margin));
            margin-left: var(--checkbox-field-label-margin-left, var(--checkbox-field-label-margin));

            // FONT
            font-size: var(--checkbox-field-label-font-size);
            letter-spacing: var(--checkbox-field-label-letter-spacing);
            font-weight: var(--checkbox-field-label-font-weight);
            font-style: var(--checkbox-field-label-font-style);
            color: var(--checkbox-field-label-color);
            font-family: var(--checkbox-field-label-font-family);
            line-height: var(--checkbox-field-label-line-height);

            &:hover {
                // MARGIN
                margin-top: var(
                    --checkbox-field-label-hover-margin-top,
                    var(--checkbox-field-label-hover-margin, var(--checkbox-field-label-margin-top, var(--checkbox-field-label-margin)))
                );
                margin-right: var(
                    --checkbox-field-label-hover-margin-right,
                    var(--checkbox-field-label-hover-margin, var(--checkbox-field-label-margin-right, var(--checkbox-field-label-margin)))
                );
                margin-bottom: var(
                    --checkbox-field-label-hover-margin-bottom,
                    var(--checkbox-field-label-hover-margin, var(--checkbox-field-label-margin-bottom, var(--checkbox-field-label-margin)))
                );
                margin-left: var(
                    --checkbox-field-label-hover-margin-left,
                    var(--checkbox-field-label-hover-margin, var(--checkbox-field-label-margin-left, var(--checkbox-field-label-margin)))
                );

                // FONT
                font-size: var(--checkbox-field-label-hover-font-size, var(--checkbox-field-label-font-size));
                letter-spacing: var(--checkbox-field-label-hover-letter-spacing, var(--checkbox-field-label-letter-spacing));
                font-weight: var(--checkbox-field-label-hover-font-weight, var(--checkbox-field-label-font-weight));
                font-style: var(--checkbox-field-label-hover-font-style, var(--checkbox-field-label-font-style));
                color: var(--checkbox-field-label-hover-color, var(--checkbox-field-label-color));
                font-family: var(--checkbox-field-label-hover-font-family, var(--checkbox-field-label-font-family));
                line-height: var(--checkbox-field-label-hover-line-height, var(--checkbox-field-label-line-height));
            }

            &:focus-within {
                // MARGIN
                margin-top: var(
                    --checkbox-field-label-hover-margin-top,
                    var(--checkbox-field-label-hover-margin, var(--checkbox-field-label-margin-top, var(--checkbox-field-label-margin)))
                );
                margin-right: var(
                    --checkbox-field-label-hover-margin-right,
                    var(--checkbox-field-label-hover-margin, var(--checkbox-field-label-margin-right, var(--checkbox-field-label-margin)))
                );
                margin-bottom: var(
                    --checkbox-field-label-hover-margin-bottom,
                    var(--checkbox-field-label-hover-margin, var(--checkbox-field-label-margin-bottom, var(--checkbox-field-label-margin)))
                );
                margin-left: var(
                    --checkbox-field-label-hover-margin-left,
                    var(--checkbox-field-label-hover-margin, var(--checkbox-field-label-margin-left, var(--checkbox-field-label-margin)))
                );

                // FONT
                font-size: var(
                    --checkbox-field-label-focus-within-font-size,
                    var(--checkbox-field-label-hover-font-size, var(--checkbox-field-label-font-size))
                );
                letter-spacing: var(
                    --checkbox-field-label-focus-within-letter-spacing,
                    var(--checkbox-field-label-hover-letter-spacing, var(--checkbox-field-label-letter-spacing))
                );
                font-weight: var(
                    --checkbox-field-label-focus-within-font-weight,
                    var(--checkbox-field-label-hover-font-weight, var(--checkbox-field-label-font-weight))
                );
                font-style: var(
                    --checkbox-field-label-focus-within-font-style,
                    var(--checkbox-field-label-hover-font-style, var(--checkbox-field-label-font-style))
                );
                color: var(
                    --checkbox-field-label-focus-within-color,
                    var(--checkbox-field-label-hover-color, var(--checkbox-field-label-color))
                );
                font-family: var(
                    --checkbox-field-label-focus-within-font-family,
                    var(--checkbox-field-label-hover-font-family, var(--checkbox-field-label-font-family))
                );
                line-height: var(
                    --checkbox-field-label-focus-within-line-height,
                    var(--checkbox-field-label-hover-line-height, var(--checkbox-field-label-line-height))
                );
            }
        }

        .choice .mdc-form-field {
            // FONT
            font-size: var(--checkbox-field-answer-font-size);
            letter-spacing: var(--checkbox-field-answer-letter-spacing);
            font-weight: var(--checkbox-field-answer-font-weight);
            font-style: var(--checkbox-field-answer-font-style);
            color: var(--checkbox-field-answer-color);
            font-family: var(--checkbox-field-answer-font-family);
            line-height: var(--checkbox-field-answer-line-height);

            &:hover {
                // FONT
                font-size: var(--checkbox-field-answer-hover-font-size, var(--checkbox-field-answer-font-size));
                letter-spacing: var(--checkbox-field-answer-hover-letter-spacing, var(--checkbox-field-answer-letter-spacing));
                font-weight: var(--checkbox-field-answer-hover-font-weight, var(--checkbox-field-answer-font-weight));
                font-style: var(--checkbox-field-answer-hover-font-style, var(--checkbox-field-answer-font-style));
                color: var(--checkbox-field-answer-hover-color, var(--checkbox-field-answer-color));
                font-family: var(--checkbox-field-answer-hover-font-family, var(--checkbox-field-answer-font-family));
                line-height: var(--checkbox-field-answer-hover-line-height, var(--checkbox-field-answer-line-height));
            }

            &:focus-within {
                // FONT
                font-size: var(
                    --checkbox-field-answer-focus-within-font-size,
                    var(--checkbox-field-answer-hover-font-size, var(--checkbox-field-answer-font-size))
                );
                letter-spacing: var(
                    --checkbox-field-answer-focus-within-letter-spacing,
                    var(--checkbox-field-answer-hover-letter-spacing, var(--checkbox-field-answer-letter-spacing))
                );
                font-weight: var(
                    --checkbox-field-answer-focus-within-font-weight,
                    var(--checkbox-field-answer-hover-font-weight, var(--checkbox-field-answer-font-weight))
                );
                font-style: var(
                    --checkbox-field-answer-focus-within-font-style,
                    var(--checkbox-field-answer-hover-font-style, var(--checkbox-field-answer-font-style))
                );
                color: var(
                    --checkbox-field-answer-focus-within-color,
                    var(--checkbox-field-answer-hover-color, var(--checkbox-field-answer-color))
                );
                font-family: var(
                    --checkbox-field-answer-focus-within-font-family,
                    var(--checkbox-field-answer-hover-font-family, var(--checkbox-field-answer-font-family))
                );
                line-height: var(
                    --checkbox-field-answer-focus-within-line-height,
                    var(--checkbox-field-answer-hover-line-height, var(--checkbox-field-answer-line-height))
                );
            }
        }

        mat-grid-tile {
            // MARGIN
            margin-top: var(--checkbox-field-answer-margin-top, var(--checkbox-field-answer-margin));
            margin-right: var(--checkbox-field-answer-margin-right, var(--checkbox-field-answer-margin));
            margin-bottom: var(--checkbox-field-answer-margin-bottom, var(--checkbox-field-answer-margin));
            margin-left: var(--checkbox-field-answer-margin-left, var(--checkbox-field-answer-margin));

            &:hover {
                margin-top: var(
                    --checkbox-field-answer-hover-margin-top,
                    var(--checkbox-field-answer-hover-margin, var(--checkbox-field-answer-margin-top, var(--checkbox-field-answer-margin)))
                );
                margin-right: var(
                    --checkbox-field-answer-hover-margin-right,
                    var(
                        --checkbox-field-answer-hover-margin,
                        var(--checkbox-field-answer-margin-right, var(--checkbox-field-answer-margin))
                    )
                );
                margin-bottom: var(
                    --checkbox-field-answer-hover-margin-bottom,
                    var(
                        --checkbox-field-answer-hover-margin,
                        var(--checkbox-field-answer-margin-bottom, var(--checkbox-field-answer-margin))
                    )
                );
                margin-left: var(
                    --checkbox-field-answer-hover-margin-left,
                    var(--checkbox-field-answer-hover-margin, var(--checkbox-field-answer-margin-left, var(--checkbox-field-answer-margin)))
                );
            }

            &:focus-within {
                margin-top: var(
                    --checkbox-field-answer-focus-within-margin-top,
                    var(
                        --checkbox-field-answer-focus-within-margin,
                        var(
                            --checkbox-field-answer-hover-margin-top,
                            var(
                                --checkbox-field-answer-hover-margin,
                                var(--checkbox-field-answer-margin-top, var(--checkbox-field-answer-margin))
                            )
                        )
                    )
                );
                margin-right: var(
                    --checkbox-field-answer-focus-within-margin-right,
                    var(
                        --checkbox-field-answer-focus-within-margin,
                        var(
                            --checkbox-field-answer-hover-margin-right,
                            var(
                                --checkbox-field-answer-hover-margin,
                                var(--checkbox-field-answer-margin-right, var(--checkbox-field-answer-margin))
                            )
                        )
                    )
                );
                margin-bottom: var(
                    --checkbox-field-answer-focus-within-margin-bottom,
                    var(
                        --checkbox-field-answer-focus-within-margin,
                        var(
                            --checkbox-field-answer-hover-margin-bottom,
                            var(
                                --checkbox-field-answer-hover-margin,
                                var(--checkbox-field-answer-margin-bottom, var(--checkbox-field-answer-margin))
                            )
                        )
                    )
                );
                margin-left: var(
                    --checkbox-field-answer-focus-within-margin-left,
                    var(
                        --checkbox-field-answer-focus-within-margin,
                        var(
                            --checkbox-field-answer-hover-margin-left,
                            var(
                                --checkbox-field-answer-hover-margin,
                                var(--checkbox-field-answer-margin-left, var(--checkbox-field-answer-margin))
                            )
                        )
                    )
                );
            }
        }

        mat-checkbox.mat-mdc-checkbox.mat-primary {
            --mdc-checkbox-unselected-icon-color: var(--checkbox-field-unselected-color);
            --mdc-checkbox-unselected-hover-icon-color: var(
                --checkbox-field-hover-unselected-color,
                var(--checkbox-field-unselected-color)
            );
            --mdc-checkbox-unselected-pressed-icon-color: var(
                --checkbox-field-hover-unselected-color,
                var(--checkbox-field-unselected-color)
            );
            --mdc-checkbox-selected-focus-icon-color: var(
                --checkbox-field-focus-within-selected-color,
                var(--checkbox-field-selected-color)
            );
            --mdc-checkbox-selected-hover-icon-color: var(--checkbox-field-hover-selected-color, var(--checkbox-field-selected-color));
            --mdc-checkbox-selected-icon-color: var(--checkbox-field-selected-color);
            --mdc-checkbox-pressed-focus-icon-color: var(
                --checkbox-field-focus-within-selected-color,
                var(--checkbox-field-selected-color)
            );
            --mdc-checkbox-pressed-hover-icon-color: var(--checkbox-field-hover-selected-color, var(--checkbox-field-selected-color));
            --mdc-checkbox-pressed-icon-color: var(--checkbox-field-selected-color);
            --mdc-theme-text-primary-on-background: var(--checkbox-field-unselected-color);
        }

        mat-checkbox.choice:hover {
            --mdc-checkbox-unselected-icon-color: var(--checkbox-field-hover-unselected-color, var(--checkbox-field-unselected-color));
            --mdc-checkbox-selected-icon-color: var(--checkbox-field-hover-selected-color, var(--checkbox-field-selected-color));
        }

        mat-checkbox.choice:focus-within,
        mat-checkbox.choice:focus,
        mat-checkbox.choice:active {
            --mdc-checkbox-unselected-icon-color: var(
                --checkbox-field-focus-within-unselected-color,
                var(--checkbox-field-hover-unselected-color, var(--checkbox-field-unselected-color))
            );
            --mdc-checkbox-selected-icon-color: var(
                --checkbox-field-focus-within-selected-color,
                var(--checkbox-field-hover-selected-color, var(--checkbox-field-hover-selected-color))
            );
        }
    }

    // Radio button
    .radiobutton-field {
        .question {
            // MARGIN
            margin-top: var(--radiobutton-field-label-margin-top, var(--radiobutton-field-label-margin));
            margin-right: var(--radiobutton-field-label-margin-right, var(--radiobutton-field-label-margin));
            margin-bottom: var(--radiobutton-field-label-margin-bottom, var(--radiobutton-field-label-margin));
            margin-left: var(--radiobutton-field-label-margin-left, var(--radiobutton-field-label-margin));

            // FONT
            font-size: var(--radiobutton-field-label-font-size);
            letter-spacing: var(--radiobutton-field-label-letter-spacing);
            font-weight: var(--radiobutton-field-label-font-weight);
            font-style: var(--radiobutton-field-label-font-style);
            color: var(--radiobutton-field-label-color);
            font-family: var(--radiobutton-field-label-font-family);
            line-height: var(--radiobutton-field-label-line-height);
        }

        &:hover {
            .question {
                // MARGIN
                margin-top: var(
                    --radiobutton-field-label-hover-margin-top,
                    var(
                        --radiobutton-field-label-hover-margin,
                        var(--radiobutton-field-label-margin-top, var(--radiobutton-field-label-margin))
                    )
                );
                margin-right: var(
                    --radiobutton-field-label-hover-margin-right,
                    var(
                        --radiobutton-field-label-hover-margin,
                        var(--radiobutton-field-label-margin-right, var(--radiobutton-field-label-margin))
                    )
                );
                margin-bottom: var(
                    --radiobutton-field-label-hover-margin-bottom,
                    var(
                        --radiobutton-field-label-hover-margin,
                        var(--radiobutton-field-label-margin-bottom, var(--radiobutton-field-label-margin))
                    )
                );
                margin-left: var(
                    --radiobutton-field-label-hover-margin-left,
                    var(
                        --radiobutton-field-label-hover-margin,
                        var(--radiobutton-field-label-margin-left, var(--radiobutton-field-label-margin))
                    )
                );

                // FONT
                font-size: var(--radiobutton-field-label-hover-font-size, var(--radiobutton-field-label-font-size));
                letter-spacing: var(--radiobutton-field-label-hover-letter-spacing, var(--radiobutton-field-label-letter-spacing));
                font-weight: var(--radiobutton-field-label-hover-font-weight, var(--radiobutton-field-label-font-weight));
                font-style: var(--radiobutton-field-label-hover-font-style, var(--radiobutton-field-label-font-style));
                color: var(--radiobutton-field-label-hover-color, var(--radiobutton-field-label-color));
                font-family: var(--radiobutton-field-label-hover-font-family, var(--radiobutton-field-label-font-family));
                line-height: var(--radiobutton-field-label-hover-line-height, var(--radiobutton-field-label-line-height));
            }
        }

        &:focus-within {
            .question {
                // MARGIN
                margin-top: var(
                    --radiobutton-field-label-hover-margin-top,
                    var(
                        --radiobutton-field-label-hover-margin,
                        var(--radiobutton-field-label-margin-top, var(--radiobutton-field-label-margin))
                    )
                );
                margin-right: var(
                    --radiobutton-field-label-hover-margin-right,
                    var(
                        --radiobutton-field-label-hover-margin,
                        var(--radiobutton-field-label-margin-right, var(--radiobutton-field-label-margin))
                    )
                );
                margin-bottom: var(
                    --radiobutton-field-label-hover-margin-bottom,
                    var(
                        --radiobutton-field-label-hover-margin,
                        var(--radiobutton-field-label-margin-bottom, var(--radiobutton-field-label-margin))
                    )
                );
                margin-left: var(
                    --radiobutton-field-label-hover-margin-left,
                    var(
                        --radiobutton-field-label-hover-margin,
                        var(--radiobutton-field-label-margin-left, var(--radiobutton-field-label-margin))
                    )
                );

                // FONT
                font-size: var(
                    --radiobutton-field-label-focus-within-font-size,
                    var(--radiobutton-field-label-hover-font-size, var(--radiobutton-field-label-font-size))
                );
                letter-spacing: var(
                    --radiobutton-field-label-focus-within-letter-spacing,
                    var(--radiobutton-field-label-hover-letter-spacing, var(--radiobutton-field-label-letter-spacing))
                );
                font-weight: var(
                    --radiobutton-field-label-focus-within-font-weight,
                    var(--radiobutton-field-label-hover-font-weight, var(--radiobutton-field-label-font-weight))
                );
                font-style: var(
                    --radiobutton-field-label-focus-within-font-style,
                    var(--radiobutton-field-label-hover-font-style, var(--radiobutton-field-label-font-style))
                );
                color: var(
                    --radiobutton-field-label-focus-within-color,
                    var(--radiobutton-field-label-hover-color, var(--radiobutton-field-label-color))
                );
                font-family: var(
                    --radiobutton-field-label-focus-within-font-family,
                    var(--radiobutton-field-label-hover-font-family, var(--radiobutton-field-label-font-family))
                );
                line-height: var(
                    --radiobutton-field-label-focus-within-line-height,
                    var(--radiobutton-field-label-hover-line-height, var(--radiobutton-field-label-line-height))
                );
            }
        }

        .choice .mdc-form-field {
            // FONT
            font-size: var(--radiobutton-field-answer-font-size);
            letter-spacing: var(--radiobutton-field-answer-letter-spacing);
            font-weight: var(--radiobutton-field-answer-font-weight);
            font-style: var(--radiobutton-field-answer-font-style);
            color: var(--radiobutton-field-answer-color);
            font-family: var(--radiobutton-field-answer-font-family);
            line-height: var(--radiobutton-field-answer-line-height);

            &:hover {
                // FONT
                font-size: var(--radiobutton-field-answer-hover-font-size, var(--radiobutton-field-answer-font-size));
                letter-spacing: var(--radiobutton-field-answer-hover-letter-spacing, var(--radiobutton-field-answer-letter-spacing));
                font-weight: var(--radiobutton-field-answer-hover-font-weight, var(--radiobutton-field-answer-font-weight));
                font-style: var(--radiobutton-field-answer-hover-font-style, var(--radiobutton-field-answer-font-style));
                color: var(--radiobutton-field-answer-hover-color, var(--radiobutton-field-answer-color));
                font-family: var(--radiobutton-field-answer-hover-font-family, var(--radiobutton-field-answer-font-family));
                line-height: var(--radiobutton-field-answer-hover-line-height, var(--radiobutton-field-answer-line-height));
            }

            &:focus-within {
                // FONT
                font-size: var(
                    --radiobutton-field-answer-focus-within-font-size,
                    var(--radiobutton-field-answer-hover-font-size, var(--radiobutton-field-answer-font-size))
                );
                letter-spacing: var(
                    --radiobutton-field-answer-focus-within-letter-spacing,
                    var(--radiobutton-field-answer-hover-letter-spacing, var(--radiobutton-field-answer-letter-spacing))
                );
                font-weight: var(
                    --radiobutton-field-answer-focus-within-font-weight,
                    var(--radiobutton-field-answer-hover-font-weight, var(--radiobutton-field-answer-font-weight))
                );
                font-style: var(
                    --radiobutton-field-answer-focus-within-font-style,
                    var(--radiobutton-field-answer-hover-font-style, var(--radiobutton-field-answer-font-style))
                );
                color: var(
                    --radiobutton-field-answer-focus-within-color,
                    var(--radiobutton-field-answer-hover-color, var(--radiobutton-field-answer-color))
                );
                font-family: var(
                    --radiobutton-field-answer-focus-within-font-family,
                    var(--radiobutton-field-answer-hover-font-family, var(--radiobutton-field-answer-font-family))
                );
                line-height: var(
                    --radiobutton-field-answer-focus-within-line-height,
                    var(--radiobutton-field-answer-hover-line-height, var(--radiobutton-field-answer-line-height))
                );
            }
        }

        mat-grid-tile {
            // MARGIN
            margin-top: var(--radiobutton-field-answer-margin-top, var(--radiobutton-field-answer-margin));
            margin-right: var(--radiobutton-field-answer-margin-right, var(--radiobutton-field-answer-margin));
            margin-bottom: var(--radiobutton-field-answer-margin-bottom, var(--radiobutton-field-answer-margin));
            margin-left: var(--radiobutton-field-answer-margin-left, var(--radiobutton-field-answer-margin));

            &:hover {
                margin-top: var(
                    --radiobutton-field-answer-hover-margin-top,
                    var(
                        --radiobutton-field-answer-hover-margin,
                        var(--radiobutton-field-answer-margin-top, var(--radiobutton-field-answer-margin))
                    )
                );
                margin-right: var(
                    --radiobutton-field-answer-hover-margin-right,
                    var(
                        --radiobutton-field-answer-hover-margin,
                        var(--radiobutton-field-answer-margin-right, var(--radiobutton-field-answer-margin))
                    )
                );
                margin-bottom: var(
                    --radiobutton-field-answer-hover-margin-bottom,
                    var(
                        --radiobutton-field-answer-hover-margin,
                        var(--radiobutton-field-answer-margin-bottom, var(--radiobutton-field-answer-margin))
                    )
                );
                margin-left: var(
                    --radiobutton-field-answer-hover-margin-left,
                    var(
                        --radiobutton-field-answer-hover-margin,
                        var(--radiobutton-field-answer-margin-left, var(--radiobutton-field-answer-margin))
                    )
                );
            }

            &:focus-within {
                margin-top: var(
                    --radiobutton-field-answer-focus-within-margin-top,
                    var(
                        --radiobutton-field-answer-focus-within-margin,
                        var(
                            --radiobutton-field-answer-hover-margin-top,
                            var(
                                --radiobutton-field-answer-hover-margin,
                                var(--radiobutton-field-answer-margin-top, var(--radiobutton-field-answer-margin))
                            )
                        )
                    )
                );
                margin-right: var(
                    --radiobutton-field-answer-focus-within-margin-right,
                    var(
                        --radiobutton-field-answer-focus-within-margin,
                        var(
                            --radiobutton-field-answer-hover-margin-right,
                            var(
                                --radiobutton-field-answer-hover-margin,
                                var(--radiobutton-field-answer-margin-right, var(--radiobutton-field-answer-margin))
                            )
                        )
                    )
                );
                margin-bottom: var(
                    --radiobutton-field-answer-focus-within-margin-bottom,
                    var(
                        --radiobutton-field-answer-focus-within-margin,
                        var(
                            --radiobutton-field-answer-hover-margin-bottom,
                            var(
                                --radiobutton-field-answer-hover-margin,
                                var(--radiobutton-field-answer-margin-bottom, var(--radiobutton-field-answer-margin))
                            )
                        )
                    )
                );
                margin-left: var(
                    --radiobutton-field-answer-focus-within-margin-left,
                    var(
                        --radiobutton-field-answer-focus-within-margin,
                        var(
                            --radiobutton-field-answer-hover-margin-left,
                            var(
                                --radiobutton-field-answer-hover-margin,
                                var(--radiobutton-field-answer-margin-left, var(--radiobutton-field-answer-margin))
                            )
                        )
                    )
                );
            }
        }

        // Select - unselected color handling
        .mat-mdc-radio-button {
            --mdc-radio-selected-icon-color: var(--radiobutton-field-selected-color) !important;
            --mdc-radio-unselected-icon-color: var(--radiobutton-field-unselected-color) !important;

            --mdc-radio-selected-hover-icon-color: var(
                --radiobutton-field-hover-selected-color,
                var(--radiobutton-field-selected-color)
            ) !important;
            --mdc-radio-unselected-hover-icon-color: var(
                --radiobutton-field-hover-unselected-color,
                var(--radiobutton-field-unselected-color)
            ) !important;

            --mdc-radio-selected-focus-icon-color: var(
                --radiobutton-field-focus-within-selected-color,
                var(--radiobutton-field-hover-selected-color, var(--radiobutton-field-selected-color))
            ) !important;
            --mdc-radio-unselected-focus-icon-color: var(
                --radiobutton-field-focus-within-unselected-color,
                var(--radiobutton-field-hover-unselected-color, var(--radiobutton-field-unselected-color))
            ) !important;

            &:focus {
                --mdc-radio-selected-icon-color: var(
                    --radiobutton-field-focus-within-selected-color,
                    var(--radiobutton-field-hover-selected-color, var(--radiobutton-field-selected-color))
                ) !important;
                --mdc-radio-unselected-icon-color: var(
                    --radiobutton-field-focus-within-unselected-color,
                    var(--radiobutton-field-hover-unselected-color, var(--radiobutton-field-unselected-color))
                ) !important;
            }

            &:focus-within {
                --mdc-radio-selected-icon-color: var(
                    --radiobutton-field-focus-within-selected-color,
                    var(--radiobutton-field-hover-selected-color, var(--radiobutton-field-selected-color))
                ) !important;
                --mdc-radio-unselected-icon-color: var(
                    --radiobutton-field-focus-within-unselected-color,
                    var(--radiobutton-field-hover-unselected-color, var(--radiobutton-field-unselected-color))
                ) !important;
            }
        }
    }

    // Slider field
    .slider-field {
        mat-slider {
            --mdc-slider-handle-color: var(--slider-color) !important;
            --mdc-slider-focus-handle-color: var(--slider-color) !important;
            --mdc-slider-hover-handle-color: var(--slider-color) !important;
            --mdc-slider-active-track-color: var(--slider-color) !important;
            --mdc-slider-inactive-track-color: var(--slider-color) !important;
            --mat-mdc-slider-ripple-color: var(--slider-color) !important;
            --mdc-slider-with-tick-marks-inactive-container-color: var(--slider-color) !important;
        }

        .question {
            // MARGIN
            margin-top: var(--slider-field-label-margin-top, var(--slider-field-label-margin));
            margin-right: var(--slider-field-label-margin-right, var(--slider-field-label-margin));
            margin-bottom: var(--slider-field-label-margin-bottom, var(--slider-field-label-margin));
            margin-left: var(--slider-field-label-margin-left, var(--slider-field-label-margin));

            // FONT
            font-size: var(--slider-field-label-font-size);
            letter-spacing: var(--slider-field-label-letter-spacing);
            font-weight: var(--slider-field-label-font-weight);
            font-style: var(--slider-field-label-font-style);
            color: var(--slider-field-label-color);
            font-family: var(--slider-field-label-font-family);
            line-height: var(--slider-field-label-line-height);

            &:hover {
                // MARGIN
                margin-top: var(
                    --slider-field-label-hover-margin-top,
                    var(--slider-field-label-hover-margin, var(--slider-field-label-margin-top, var(--slider-field-label-margin)))
                );
                margin-right: var(
                    --slider-field-label-hover-margin-right,
                    var(--slider-field-label-hover-margin, var(--slider-field-label-margin-right, var(--slider-field-label-margin)))
                );
                margin-bottom: var(
                    --slider-field-label-hover-margin-bottom,
                    var(--slider-field-label-hover-margin, var(--slider-field-label-margin-bottom, var(--slider-field-label-margin)))
                );
                margin-left: var(
                    --slider-field-label-hover-margin-left,
                    var(--slider-field-label-hover-margin, var(--slider-field-label-margin-left, var(--slider-field-label-margin)))
                );

                // FONT
                font-size: var(--slider-field-label-hover-font-size, var(--slider-field-label-font-size));
                letter-spacing: var(--slider-field-label-hover-letter-spacing, var(--slider-field-label-letter-spacing));
                font-weight: var(--slider-field-label-hover-font-weight, var(--slider-field-label-font-weight));
                font-style: var(--slider-field-label-hover-font-style, var(--slider-field-label-font-style));
                color: var(--slider-field-label-hover-color, var(--slider-field-label-color));
                font-family: var(--slider-field-label-hover-font-family, var(--slider-field-label-font-family));
                line-height: var(--slider-field-label-hover-line-height, var(--slider-field-label-line-height));
            }

            &:focus-within {
                // MARGIN
                margin-top: var(
                    --slider-field-label-hover-margin-top,
                    var(--slider-field-label-hover-margin, var(--slider-field-label-margin-top, var(--slider-field-label-margin)))
                );
                margin-right: var(
                    --slider-field-label-hover-margin-right,
                    var(--slider-field-label-hover-margin, var(--slider-field-label-margin-right, var(--slider-field-label-margin)))
                );
                margin-bottom: var(
                    --slider-field-label-hover-margin-bottom,
                    var(--slider-field-label-hover-margin, var(--slider-field-label-margin-bottom, var(--slider-field-label-margin)))
                );
                margin-left: var(
                    --slider-field-label-hover-margin-left,
                    var(--slider-field-label-hover-margin, var(--slider-field-label-margin-left, var(--slider-field-label-margin)))
                );

                // FONT
                font-size: var(
                    --slider-field-label-focus-within-font-size,
                    var(--slider-field-label-hover-font-size, var(--slider-field-label-font-size))
                );
                letter-spacing: var(
                    --slider-field-label-focus-within-letter-spacing,
                    var(--slider-field-label-hover-letter-spacing, var(--slider-field-label-letter-spacing))
                );
                font-weight: var(
                    --slider-field-label-focus-within-font-weight,
                    var(--slider-field-label-hover-font-weight, var(--slider-field-label-font-weight))
                );
                font-style: var(
                    --slider-field-label-focus-within-font-style,
                    var(--slider-field-label-hover-font-style, var(--slider-field-label-font-style))
                );
                color: var(--slider-field-label-focus-within-color, var(--slider-field-label-hover-color, var(--slider-field-label-color)));
                font-family: var(
                    --slider-field-label-focus-within-font-family,
                    var(--slider-field-label-hover-font-family, var(--slider-field-label-font-family))
                );
                line-height: var(
                    --slider-field-label-focus-within-line-height,
                    var(--slider-field-label-hover-line-height, var(--slider-field-label-line-height))
                );
            }
        }
    }

    // DIVIDER
    mat-divider {
        --mat-divider-color: var(--divider-color);
    }

    // Tabs
    .mat-mdc-tab-group,
    .mat-mdc-tab-nav-bar {
        --mat-tab-header-active-label-text-color: var(--tab-accent-color);
        --mat-tab-header-active-hover-label-text-color: var(--tab-hover-accent-color, var(--tab-accent-color));
        --mat-tab-header-active-hover-indicator-color: var(--tab-hover-accent-color, var(--tab-accent-color));
        --mat-tab-header-active-focus-label-text-color: var(--tab-accent-color);
        --mat-tab-header-active-focus-indicator-color: var(--tab-accent-color);
        --mat-tab-header-active-ripple-color: var(--tab-accent-color);
        --mdc-tab-indicator-active-indicator-color: var(--tab-accent-color);
        --mat-tab-header-inactive-ripple-color: var(--tab-accent-color);
    }

    mat-tab-body {
        border-bottom-left-radius: var(--part-border-bottom-radius, var(--part-border-radius));
        border-bottom-right-radius: var(--part-border-right-radius, var(--part-border-radius));
    }

    mat-tab-header.mat-mdc-tab-header {
        border-top-left-radius: var(--part-border-bottom-radius, var(--part-border-radius));
        border-top-right-radius: var(--part-border-right-radius, var(--part-border-radius));
        --mat-tab-header-label-text-font: var(--tab-font-family);
        --mat-tab-header-label-text-size: var(--tab-font-size);
        --mat-tab-header-label-text-line-height: var(--tab-line-height);
        --mat-tab-header-label-text-weight: var(--tab-font-weight);
        --mat-tab-header-inactive-label-text-color: var(--tab-color);
        --mat-tab-header-inactive-focus-label-text-color: var(--tab-color);
        --mat-tab-header-inactive-hover-label-text-color: var(--tab-color);
    }

    // Links
    .link {
        font-style: var(--link-font-style);
        font-family: var(--link-font-family);
        font-size: var(--link-font-size);
        font-weight: var(--link-font-weight);
        color: var(--link-color);
        line-height: var(--link-font-line-height);
        letter-spacing: var(--link-letter-spacing);
        font-stretch: var(--link-font-stretch);
        font-variant: var(--link-font-variant);
        font-variant-caps: var(--link-font-variant-caps);
        text-align: var(--link-text-align);
        text-decoration-line: var(--link-text-decoration-line);
        text-decoration-style: var(--link-text-decoration-style);
        text-decoration-color: var(--link-text-decoration-color);
        text-decoration-thickness: var(--link-text-decoration-thickness);
    }

    .link:hover {
        font-style: var(--link-hover-font-style, var(--link-font-style));
        font-family: var(--link-hover-font-family, var(--link-font-family));
        font-size: var(--link-hover-font-size, var(--link-font-size));
        font-weight: var(--link-hover-font-weight, var(--link-font-weight));
        color: var(--link-hover-color, var(--link-color));
        line-height: var(--link-hover-font-line-height, var(--link-font-line-height));
        letter-spacing: var(--link-hover-letter-spacing, var(--link-letter-spacing));
        font-stretch: var(--link-hover-font-stretch, var(--link-font-stretch));
        font-variant: var(--link-hover-font-variant, var(--link-font-variant));
        font-variant-caps: var(--link-hover-font-variant-caps, var(--link-font-variant-caps));
        text-align: var(--link-hover-text-align, var(--link-text-align));
        text-decoration-line: var(--link-hover-text-decoration-line, var(--link-text-decoration-line));
        text-decoration-style: var(--link-hover-text-decoration-style, var(--link-text-decoration-style));
        text-decoration-color: var(--link-hover-text-decoration-color, var(--link-text-decoration-color));
        text-decoration-thickness: var(--link-hover-text-decoration-thickness, var(--link-text-decoration-thickness));
    }

    .link:focus {
        font-style: var(--link-focus-font-style, var(--link-font-style));
        font-family: var(--link-focus-font-family, var(--link-font-family));
        font-size: var(--link-focus-font-size, var(--link-font-size));
        font-weight: var(--link-focus-font-weight, var(--link-font-weight));
        color: var(--link-focus-color, var(--link-color));
        line-height: var(--link-focus-font-line-height, var(--link-font-line-height));
        letter-spacing: var(--link-focus-letter-spacing, var(--link-letter-spacing));
        font-stretch: var(--link-focus-font-stretch, var(--link-font-stretch));
        font-variant: var(--link-focus-font-variant, var(--link-font-variant));
        font-variant-caps: var(--link-focus-font-variant-caps, var(--link-font-variant-caps));
        text-align: var(--link-focus-text-align, var(--link-text-align));
        text-decoration-line: var(--link-focus-text-decoration-line, var(--link-text-decoration-line));
        text-decoration-style: var(--link-focus-text-decoration-style, var(--link-text-decoration-style));
        text-decoration-color: var(--link-focus-text-decoration-color, var(--link-text-decoration-color));
        text-decoration-thickness: var(--link-focus-text-decoration-thickness, var(--link-text-decoration-thickness));
    }

    .link:active {
        font-style: var(--link-active-font-style, var(--link-font-style));
        font-family: var(--link-active-font-family, var(--link-font-family));
        font-size: var(--link-active-font-size, var(--link-font-size));
        font-weight: var(--link-active-font-weight, var(--link-font-weight));
        color: var(--link-active-color, var(--link-color));
        line-height: var(--link-active-font-line-height, var(--link-font-line-height));
        letter-spacing: var(--link-active-letter-spacing, var(--link-letter-spacing));
        font-stretch: var(--link-active-font-stretch, var(--link-font-stretch));
        font-variant: var(--link-active-font-variant, var(--link-font-variant));
        font-variant-caps: var(--link-active-font-variant-caps, var(--link-font-variant-caps));
        text-align: var(--link-active-text-align, var(--link-text-align));
        text-decoration-line: var(--link-active-text-decoration-line, var(--link-text-decoration-line));
        text-decoration-style: var(--link-active-text-decoration-style, var(--link-text-decoration-style));
        text-decoration-color: var(--link-active-text-decoration-color, var(--link-text-decoration-color));
        text-decoration-thickness: var(--link-active-text-decoration-thickness, var(--link-text-decoration-thickness));
    }

    // NEXT

    img:hover {
        object-fit: var(--image-hover-object-fit, var(--image-object-fit));
    }

    span.svg-image svg {
        height: 100%;
        width: 100%;
    }

    span.svg-image {
        height: 100%;
        width: 100%;
        display: block;
        color: var(--image-color);
    }

    span.svg-image:hover {
        color: var(--image-hover-color, var(--image-color));
    }

    // TITLE
    h1 {
        --title-font-size: var(--headline-large-font-size);
        --title-letter-spacing: var(--headline-large-letter-spacing);
        --title-font-weight: var(--headline-large-font-weight);
        --title-font-style: var(--headline-large-font-style);
        --title-color: var(--headline-large-font-color);
        --title-font-family: var(--headline-large-font-family);
    }

    h2 {
        --title-font-size: var(--headline-medium-font-size);
        --title-letter-spacing: var(--headline-medium-letter-spacing);
        --title-font-weight: var(--headline-medium-font-weight);
        --title-font-style: var(--headline-medium-font-style);
        --title-color: var(--headline-medium-font-color);
        --title-font-family: var(--headline-medium-font-family);
    }

    h3 {
        --title-font-size: var(--headline-small-font-size);
        --title-letter-spacing: var(--headline-small-letter-spacing);
        --title-font-weight: var(--headline-small-font-weight);
        --title-font-style: var(--headline-small-font-style);
        --title-color: var(--headline-small-font-color);
        --title-font-family: var(--headline-small-font-family);
    }

    h4 {
        --title-font-size: var(--title-large-font-size);
        --title-letter-spacing: var(--title-large-letter-spacing);
        --title-font-weight: var(--title-large-font-weight);
        --title-font-style: var(--title-large-font-style);
        --title-color: var(--title-large-font-color);
        --title-font-family: var(--title-large-font-family);
    }

    h5 {
        --title-font-size: var(--title-medium-font-size);
        --title-letter-spacing: var(--title-medium-letter-spacing);
        --title-font-weight: var(--title-medium-font-weight);
        --title-font-style: var(--title-medium-font-style);
        --title-color: var(--title-medium-font-color);
        --title-font-family: var(--title-medium-font-family);
    }

    h6 {
        --title-font-size: var(--title-small-font-size);
        --title-letter-spacing: var(--title-small-letter-spacing);
        --title-font-weight: var(--title-small-font-weight);
        --title-font-style: var(--title-small-font-style);
        --title-color: var(--title-small-font-color);
        --title-font-family: var(--title-small-font-family);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: var(--title-font-size) !important;
        letter-spacing: var(--title-letter-spacing) !important;
        font-weight: var(--title-font-weight) !important;
        font-style: var(--title-font-style) !important;
        color: var(--title-color) !important;
        font-family: var(--title-font-family) !important;
        line-height: var(--title-line-height) !important;
        text-align: var(--title-text-align) !important;

        font-variant: var(--title-font-variant, normal) !important;
        font-variant-caps: var(--title-font-variant-caps, normal) !important;
        font-stretch: var(--title-font-stretch) !important;

        text-decoration-line: var(--title-text-decoration-line);
        text-decoration-style: var(--title-text-decoration-style);
        text-decoration-color: var(--title-text-decoration-color);
        text-decoration-thickness: var(--title-text-decoration-thickness);
    }

    codex-datatable-part-front mat-form-field {
        --input-border-color: var(--alternate-color);
        --input-background-color: var(--part-background-color);
    }

    codex-datatable-part-front .part-content .mat-mdc-icon-button:hover {
        --mat-icon-button-hover-state-layer-opacity: 0.04;
        --mat-icon-button-state-layer-color: var(--primary-color);
    }

    codex-datatable-part-front .part-content .mat-mdc-option:hover {
        background-color: var(--primary-color);
    }

    codex-datatable-part-front .part-content {
        overflow: hidden;
    }
}
